/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
// TODO: Separate functions for periodic updates
// TODO: Handle maxout by daily earnings
import React, { useCallback, useEffect, useRef, useState } from "react";
import { ethers, providers } from "ethers";
import styled from "styled-components";
import {
  passiveEarningIcon,
  withdrawhistoryIcon,
  backgroundImage,
  abi,
  contractAddress,
  addfuel,
  bnbcoinicon,
  teamearnings,
  bnblogoWhite,
  loading,
} from "./assets";

import WarningIcon from "@mui/icons-material/Warning";
import PauseIcon from "@mui/icons-material/Pause";
import BlockIcon from "@mui/icons-material/Block";
import CloseIcon from "@mui/icons-material/Close";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";

import Snackbar from "@mui/material/Snackbar";

import { Button } from "@mui/material";

// **********************
import IconButton from "@mui/material/IconButton";

import { useParams } from "react-router-dom";
import Navbar from "./Navbar";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { parse } from "url";
import AutorenewOutlinedIcon from "@mui/icons-material/AutorenewOutlined";
import Meter from "./Meter";
import { ContactEmergency } from "@mui/icons-material";
import ClickAwayListener from "react-click-away-listener";
import { relative } from "path";

import { useWeb3Modal } from '@web3modal/react'
import { useAccount, useDisconnect } from 'wagmi'
import { WalletClient, getWalletClient } from '@wagmi/core'

const _MS_PER_DAY = 1000 * 60 * 60 * 24;

function GrowthEngine({
  switchNetwork,
  contractBalance,
  disconnect,
  provider,
  library,
  account,
  setAccount,
  message,
  setMessage,
  snackBarOpen,
  setSnackBar,
  connectWallet,
  setSnackBarStyle,
  snackBarStyle,
}) {
  const { open } = useWeb3Modal()
  const { isConnected } = useAccount()

  const userDetails = {
    balance: 0,
    balanceFull: 0,
    activeStakePresent: false,
    activeStake: 0,
    timepassed: 0,
    currentEarning: 0,
    maxEarning: 0,
    dailyEarningRate: 0,
    dailyEarnings: 0,
    passiveEarning: 0,
    DailyGrowth: 0,
    TeamEarning: 0,
    currentTeamEarning: 0,
    EarningsByLevel: [0, 0, 0, 0, 0],
    teamSize: [0, 0, 0, 0, 0],
    progress: 0,
    maxReferralLevel: 0,
    totalTeamSize: 0,
    isRegistered: false,
    downline: 0,
    totalReferralEarning: 0,
    downlineStakeInfo: [0, 0, 0, 0, 0],
    cycles: 0,
    AllTimeBNB: 0,
    AllTimeProgress: 0,
    progressin100: 0,
  };

  const AdminDetails = {
    member: 0,
    balance: 0,
    teamSize: 0,
    downline: 0,
    depositFee: 0,
    totalEarnings: 0, //<-------------------------- CHECK THIS
    withdrawalFee: 0,
    referralFee: 0,
    performanceFee: 0,
    residualCommissions: 0,
    bnbStaked: 0,
    volumeLocked: 0,
    reserveBalance: 0,
    bnbWithdrawan: 0,
    runningTime: 0,
    fees: 0,
    teamEarning: 0,
  };

  const [adminWithdrawalInput, setAdminWithdrawalInput] = useState(0);
  const [amountToStake, setAmountToStake] = useState();
  const [stakeAfterFee, setStakeAfterFee] = useState();
  const [amountToWithdraw, setAmountToWithdraw] = useState(0);
  const [withdrawAfterFee, setWithdrawAfterFee] = useState(0);
  const [withdrawFee, setWithdrawFee] = useState(0);
  const [whaleTax, setWhaleTaxAmount] = useState(0);
  const [userFuelHistory, setUserFuelHistoy] = useState([]);
  const [userWithdrawHistory, setUserWithdrawHistory] = useState([]);

  const [admin, setAdmin] = useState(false);
  const [nextOwner, setNextOwner] = useState(false);

  const [showFuel, setFuelVisibility] = useState(false);
  const [showWithdraw, setWithdrawVisibility] = useState(false);
  const [showAdminWithdraw, setAdminWithdrawVisibility] = useState(false);
  const [showVariable, setVariableVisibility] = useState(false);
  const [showStatus, setStatusVisibility] = useState(false);
  const [showSuspend, setSuspendVisibility] = useState(false);
  const [showMiscellaneous, setMiscellaneousVisibility] = useState(false);
  const [showGrowth, setShowGrowth] = useState(false);
  const [showPassiveEarnings, setShowPassiveEarnings] = useState(false);
  const [teamEarning, showTeamEarning] = useState(false);
  const [level1, showlevel1] = useState(false);
  const [level2, showlevel2] = useState(false);
  const [level3, showlevel3] = useState(false);
  const [level4, showlevel4] = useState(false);
  const [level5, showlevel5] = useState(false);
  const [teamLevelEarnings, setShowTeamLevelEarnings] = useState(false);
  const [maxed, setMaxed] = useState(false);
  const [maxWithdrawalError, setMaxWithdrawalError] = useState(false);
  const [max24hourError, set24hourError] = useState(false);
  const [owner, setOwner] = useState();
  const [showFuelHistory, setShowFuelHistory] = useState(false);
  const [showWithdrawHistory, setShowWithdrawHistory] = useState(false);
  const [refereeError, setRefereeError] = useState(false);
  const [refereeErrorShow, setRefereeErrorShow] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [fuelError, setFuelError] = useState(false);
  const [depositFeeInfo, setDepositFeeInfo] = useState({
    fee: 0,
    adminShare: 0,
    liquidty: 0,
  });

  const [withdrawalFeeInfo, setWithdrawalFeeInfo] = useState({
    fee: 0,
    adminShare: 0,
    liquidty: 0,
    directReferee: 0,
  });

  const [performanceFeeInfo, setPerformanceFeeInfo] = useState({
    fee: 0,
    adminShare: 0,
    liquidty: 0,
    upline: 0,
  });

  const [RCFInfo, setRCFInfo] = useState({
    fee: 0,
    adminShare: 0,
    liquidty: 0,
  });

  const [earningRatesInfo, setEarningRatesInfo] = useState({
    A: 0,
    B: 0,
    C: 0,
  });

  const [minimumStakeInfo, setMinimumStakeInfo] = useState(0);
  const [maximumStakeInfo, setMaximumStakeInfo] = useState(0);

  const [editDepositFee, setEditDepositFee] = useState(false);
  const [editWithdrawalFee, setEditWithdrawalFee] = useState(false);
  const [editPerformanceFee, setEditPerformanceFee] = useState(false);
  const [editRFCFee, setEditRFCFee] = useState(false);
  const [editEarningRates, setEditEarningRates] = useState(false);
  const [editMinStake, setEditMinStake] = useState(false);
  const [editMaxStake, setEditMaxStake] = useState(false);

  const [userDets, UpdateUserDets] = useState(userDetails);
  const [adminDets, UpdateAdminDets] = useState(AdminDetails);

  const [depositFee, setDepositFee] = useState();
  const [depositFeeAdminShare, setDepositFeeAdminShare] = useState();
  const [depositFeeLiquidityShare, setDepositFeeLiquidityShare] = useState();

  const [withdrawalFee, setWithdrawalWithdrawal] = useState();
  const [withdrawalFeeAdminShare, setWithdrawalFeeAdminShare] = useState();
  const [withdrawalFeeLiquidityShare, setWithdrawalFeeLiquidityShare] =
    useState();
  const [withdrawalFeeDS, setWithdrawalFeeDS] = useState();

  const [performanceFee, setPerformancedrawal] = useState();
  const [performanceFeeAdminShare, setPerformanceFeeAdminShare] = useState();
  const [performanceFeeLiquidityShare, setPerformanceFeeLiquidityShare] =
    useState();
  const [performanceFeeUpline, setPerformanceFeeUpline] = useState();

  const [rfCFee, setRFCFee] = useState();
  const [rfCFeeAdminShare, setRFCFeeAdminShare] = useState();
  const [rfCFeeLiquidityShare, setRFCFeeLiquidityShare] = useState();

  const [earningRateA, setEarningRateA] = useState();
  const [earningRateB, setEarningRateB] = useState();
  const [earningRateC, setEarningRateC] = useState();

  const [minStake, setMinStake] = useState();
  const [maxStake, setMaxStake] = useState();

  const [actionUser, setActionUser] = useState();

  const [withdrawalAmount, setWithdrawalAmount] = useState();

  function walletClientToSigner(walletClient) {
    const { account, chain, transport } = walletClient
    const network = {
      chainId: 56,
      name: "BSC",
      ensAddress: undefined,
    }
    const provider = new providers.Web3Provider(transport, network)
    const signer = provider.getSigner(account.address)
    return signer
  }
   
  /** Action to convert a viem Wallet Client to an ethers.js Signer. */
  async function getEthersSigner(chainId) {
    const walletClient = await getWalletClient({ chainId })
    console.log(walletClient)
    if (!walletClient) return undefined
    return walletClientToSigner(walletClient)
  }

  const { referee } = useParams();

  async function onOpen() {
    await open()
  }

  function openModal() {
    console.log(account)
    if(!account){
        onOpen()
    }
  }

  const checkReferee = async () => {
    if (referee !== undefined) {
      const valid = ethers.utils.isAddress(referee);

      if (!valid) {
        setRefereeError(true);
      } 
      else if (account !== undefined) {
        try {
          // if wallet connected, we have access to library
          const contractRead = new ethers.Contract(
            contractAddress,
            abi,
            library
          );
          const isRegistered = await contractRead.checkUserRegistry(
            referee?.toString()
          );

          const owner = await contractRead.owner();

          if (referee.toLowerCase() === owner.toLowerCase()) {
            setRefereeError(false)
          }
          else {
            setRefereeError(!isRegistered);
          }
          
          
        } catch (e) {
          console.log("Check Referee Error", e);
        }
      }
    }
    else {
      const contractRead = new ethers.Contract(
        contractAddress,
        abi,
        library
      );

      const isRegistered = await contractRead.checkUserRegistry(
        account.toString()
      );

      setRefereeError(!isRegistered);
    }
    
  };

  const toggleFuelVisibility = () => {
    if (account !== undefined) {
      if (refereeError) {
        setRefereeErrorShow(true)
      }
      else {
        setFuelVisibility(!showFuel);
      }
      
    } 
  };

  const toggleGrowthVisibility = () => {
    if (account !== undefined) setShowGrowth(!showGrowth);
  };

  const toggleWithdrawVisibility = () => {
    if (account !== undefined) setWithdrawVisibility(!showWithdraw);
  };

  const toggleTeamLevelEarningVisibility = () => {
    if (account !== undefined) setShowTeamLevelEarnings(!teamLevelEarnings);
  };

  const toggleAdminWithdrawVisibility = () => {
    if (account !== undefined) setAdminWithdrawVisibility(!showAdminWithdraw);
  };
  const toggleVariableVisibility = () => {
    if (account !== undefined) setVariableVisibility(!showVariable);
  };

  const toggleStatusVisibility = () => {
    if (account !== undefined) setStatusVisibility(!showStatus);
  };

  const toggleSuspendVisibility = () => {
    if (account !== undefined) setSuspendVisibility(!showSuspend);
  };

  const toggleMiscellaneousVisibility = () => {
    if (account !== undefined) setMiscellaneousVisibility(!showMiscellaneous);
  };

  const toggleTeamEarningVisibility = () => {
    if (account !== undefined) showTeamEarning(!teamEarning);
  };

  const togglelevel1Visibility = () => {
    if (account !== undefined) showlevel1(!level1);
  };

  const togglelevel2Visibility = () => {
    if (account !== undefined) showlevel2(!level2);
  };
  const togglelevel3Visibility = () => {
    if (account !== undefined) showlevel3(!level3);
  };
  const togglelevel4Visibility = () => {
    if (account !== undefined) showlevel4(!level4);
  };
  const togglelevel5Visibility = () => {
    if (account !== undefined) showlevel5(!level5);
  };

  const toggleShowFuelHistory = () => {
    if (account !== undefined) {
      setShowFuelHistory(!showFuelHistory);
    }
  };

  const toggleWithdrawHistoryVisibility = () => {
    if (account !== undefined) {
      setShowWithdrawHistory(!showWithdrawHistory);
    }
  };

  const checkAdmin = useCallback(async () => {
    const contract = new ethers.Contract(contractAddress, abi, library);

    const owner = await contract?.owner();

    setOwner(owner?.toLowerCase());
    const nextOwner = await contract?.nextOwner();
    if (account.toLowerCase() === owner.toLowerCase()) {
      setAdmin(true);
      await updateAdminInfo();
    } else {
      // CHANGE THIS***************************************************************************************************************
      // CHANGE THIS***************************************************************************************************************
      // CHANGE THIS***************************************************************************************************************
      // CHANGE THIS***************************************************************************************************************
      setAdmin(false);
      //await updateAdminInfo();
    }
    console.log(account.toLowerCase());
    console.log(nextOwner.toLowerCase());
    console.log("+++++++++++++++++++++++++");
    if (account.toLowerCase() === nextOwner.toLowerCase()) {
      setNextOwner(true);
    } else {
      setNextOwner(false);
    }
  });

  const stakeBNB = async () => {
    var userBalance = await library?.getBalance(account);
    userBalance = ethers.utils.formatEther(userBalance);
    if (parseFloat(amountToStake) > parseFloat(maximumStakeInfo)) {
      
      setMessage(`The maximum stake value is ${parseInt(maximumStakeInfo)}BNB`);
      setSnackBarStyle("snackBarRed");

      setAmountToStake(0);
      setStakeAfterFee(0);
      setSnackBar(true);

      return
    }

    if (parseFloat(userBalance) < parseFloat(amountToStake)) {
      setMessage(`Insufficient funds to stake ${amountToStake}BNB`);
      setSnackBarStyle("snackBarRed");

      setAmountToStake(0);
      setStakeAfterFee(0);
      setSnackBar(true);

      return
    }

    /*
    const contractRead = new ethers.Contract(contractAddress, abi, library);
    var minimumStakeValue = parseInt(await contractRead.minimumStakeValue());
    const amount = ethers.utils.parseEther(`${amountToStake}`);

    if (parseInt(amount) >= minimumStakeValue) {
      minimumStakeValue = ethers.utils.formatEther(minimumStakeValue?.toString());
      setMessage(`The minimum stake value is ${minimumStakeValue}BNB`);
      setSnackBarStyle("snackBarRed");

      setAmountToWithdraw(0);
      setSnackBar(true);

      return
    }*/

    try {
      const contractRead = new ethers.Contract(contractAddress, abi, library);
      //const signer = library.getSigner();
      const signer = await getEthersSigner(56);
      //console.log("Signer", signer)
      console.log("Signer2", signer)
      const contract = new ethers.Contract(contractAddress, abi, signer);

      toggleFuelVisibility();

        var owner = await contractRead.owner();
        if (referee !== undefined) {
          owner = referee;
        } 

        await updateUserInfo()
      
          console.log(owner)
          const options = { value: ethers.utils.parseEther(`${amountToStake}`) };
          const txResponse = await contract.stakeBNB(owner?.toString(), options);
          console.log(txResponse)

          var message1;
          var message2;
          if (!userDets.isRegistered) {
            message1 = `Registering User & Staking BNB`
            message2 = `User Registered & ${stakeAfterFee}BNB Staked`
          }
          else {
            message1 = `Staking BNB`
            message2 = `${stakeAfterFee}BNB Staked`
          }

          setMessage(
            <p>
              {" "}
              {message1}{" "}
              <a
                href={`https://bscscan.com/tx/${txResponse.hash}`}
                target="_blank"
                rel="noreferrer"
              >
                View
              </a>{" "}
            </p>
          );
          setSnackBarStyle("snackBarGreen");
          setSnackBar(true);
          const txReceipt = await txResponse.wait();

          setMessage(message2);
          setSnackBar(true);
        
          toggleFuelVisibility();
          setSnackBarStyle("snackBarGreen");

          await updateUserInfo();
          setAmountToStake(0);
          setStakeAfterFee(0);
          setSnackBar(true);
    } 
    catch (e) {
      console.log(e)
      //alert(e.data.message)
      
      if (e.reason === undefined) {
        setMessage(e.data.message);
      } 
      else {
        setMessage(e.reason);
      }
      setSnackBarStyle("snackBarRed");
      setAmountToStake(0);
      setStakeAfterFee(0);
      setSnackBar(true);
      
    }
  };

  const WithdrawBNB = async () => {
    const contractRead = new ethers.Contract(
      contractAddress,
      abi,
      library
    );
    
    var minimumWithdrawalAmount = parseInt(await contractRead.minimumWithdrawalAmount());
    const amount = ethers.utils.parseEther(withdrawalAmount?.toString());

    if (parseInt(amount) < minimumWithdrawalAmount) {
      minimumWithdrawalAmount = ethers.utils.formatEther(minimumWithdrawalAmount?.toString());
      setMessage(`The minimum withdrawal amount is ${minimumWithdrawalAmount}BNB`);
      setSnackBarStyle("snackBarRed");

      setAmountToWithdraw(0);
      setSnackBar(true);

      return
    }
    
    try {
      const signer = await getEthersSigner(56);
      const contract = new ethers.Contract(contractAddress, abi, signer);
      
      //alert(amount)
      const txResponse = await contract.withdrawEarnings(amount?.toString());

      toggleWithdrawVisibility();
      setMessage(
        <p>
          {" "}
          Transaction initiated.{" "}
          <a
            href={`https://bscscan.com/tx/${txResponse.hash}`}
            target="_blank"
            rel="noreferrer"
          >
            View
          </a>{" "}
        </p>
      );
      setSnackBarStyle("snackBarGreen");

      setSnackBar(true);

      const txReceipt = await txResponse.wait();
      setMessage(`${withdrawAfterFee}BNB withdrawn`);
      await updateUserInfo();
      setAmountToWithdraw(0);
      await updateUserInfo();
      setSnackBar(true);
    } catch (e) {
      if (e.reason === undefined) setMessage(e.message);
      else setMessage(e.reason);
      setSnackBarStyle("snackBarRed");

      setAmountToWithdraw(0);
      setSnackBar(true);
    }
  };

  const WithdrawAdminEarnings = async () => {
    try {
      const signer = await library.getSigner();
      const contract = new ethers.Contract(contractAddress, abi, signer);
      //const amount = ethers.utils.parseEther(`${adminDets.balance}`);
      alert(adminDets.balance)
      alert(adminWithdrawalInput)
      const amount = ethers.utils.parseEther(`${adminWithdrawalInput}`); 
      //alert(amount)
      const txResponse = await contract.WithdrawAdminEarnings(
        amount?.toString()
      );

      toggleAdminWithdrawVisibility();
      setMessage(
        <p>
          {" "}
          Transaction initiated.{" "}
          <a
            href={`https://bscscan.com/tx/${txResponse.hash}`}
            target="_blank"
            rel="noreferrer"
          >
            View
          </a>{" "}
        </p>
      );
      setSnackBarStyle("snackBarGreen");

      setSnackBar(true);

      const txReceipt = await txResponse.wait();
      setAmountToWithdraw(0);
      setWithdrawAfterFee(0);
      await updateAdminInfo();
    } catch (e) {
      toggleAdminWithdrawVisibility();

      if (e.reason === undefined) setMessage(e.message);
      else setMessage(e.reason);
      setSnackBarStyle("snackBarRed");

      setAmountToWithdraw(0);
      setSnackBar(true);
    }
  };

  const dateDifference = (a, b) => {
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
  };

  const updateDepositFee = async () => {
    try {
      const signer = await library.getSigner();
      const contract = new ethers.Contract(contractAddress, abi, signer);

      const txResponse = await contract.changeDepositFee(
        depositFee * 100,
        depositFeeAdminShare * 100,
        depositFeeLiquidityShare * 100
      );

      setMessage(
        <p>
          {" "}
          Transaction initiated.{" "}
          <a
            href={`https://bscscan.com/tx/${txResponse.hash}`}
            target="_blank"
            rel="noreferrer"
          >
            View
          </a>{" "}
        </p>
      );
      setSnackBarStyle("snackBarGreen");

      setSnackBar(true);

      const txReceipt = await txResponse.wait();

      setMessage("Deposit Fee Updated");
      setDepositFee(0);
      setDepositFeeAdminShare(0);
      setDepositFeeLiquidityShare(0);
      setSnackBar(true);
    } catch (e) {
      if (e.reason === undefined) setMessage(e.message);
      else setMessage(e.reason);
      setSnackBarStyle("snackBarRed");

      setDepositFee(0);
      setDepositFeeAdminShare(0);
      setDepositFeeLiquidityShare(0);
      setSnackBar(true);
    }
    setEditDepositFee(false);
  };

  const updatewithdrawalFee = async () => {
    try {
      const signer = await library.getSigner();
      const contract = new ethers.Contract(contractAddress, abi, signer);

      const txResponse = await contract.changeWithdrawalFee(
        withdrawalFee * 100,
        withdrawalFeeAdminShare * 100,
        withdrawalFeeLiquidityShare * 100,
        withdrawalFeeDS * 100
      );

      setMessage(
        <p>
          {" "}
          Transaction initiated.{" "}
          <a
            href={`https://bscscan.com/tx/${txResponse.hash}`}
            target="_blank"
            rel="noreferrer"
          >
            View
          </a>{" "}
        </p>
      );
      setSnackBarStyle("snackBarGreen");

      setSnackBar(true);

      const txReceipt = await txResponse.wait();

      setMessage("Withdrawal Fees updated");

      setSnackBar(true);

      setWithdrawalWithdrawal(0);
      setWithdrawalFeeAdminShare(0);
      setWithdrawalFeeLiquidityShare(0);
      setWithdrawalFeeDS(0);
    } catch (e) {
      if (e.reason === undefined) setMessage(e.message);
      else setMessage(e.reason);
      setSnackBarStyle("snackBarRed");

      setWithdrawalWithdrawal(0);
      setWithdrawalFeeAdminShare(0);
      setWithdrawalFeeLiquidityShare(0);
      setWithdrawalFeeDS(0);
      setSnackBar(true);
    }
    setEditWithdrawalFee(false);
  };

  const updateperformanceFee = async () => {
    try {
      const signer = await library.getSigner();
      const contract = new ethers.Contract(contractAddress, abi, signer);
      // const amount = ethers.utils.parseEther(`${amountToWithdraw}`)

      const txResponse = await contract.changePerformanceFee(
        performanceFee * 100,
        performanceFeeAdminShare * 100,
        performanceFeeLiquidityShare * 100,
        performanceFeeUpline * 100
      );

      setMessage(
        <p>
          {" "}
          Transaction initiated.{" "}
          <a
            href={`https://bscscan.com/tx/${txResponse.hash}`}
            target="_blank"
            rel="noreferrer"
          >
            View
          </a>{" "}
        </p>
      );
      setSnackBarStyle("snackBarGreen");

      setSnackBar(true);

      const txReceipt = await txResponse.wait();

      setMessage("Performance Fee updated");
      setSnackBar(true);

      setPerformancedrawal(0);
      setPerformanceFeeAdminShare(0);
      setPerformanceFeeLiquidityShare(0);
      setPerformanceFeeUpline(0);
    } catch (e) {
      if (e.reason === undefined) setMessage(e.message);
      else setMessage(e.reason);
      setSnackBarStyle("snackBarRed");

      setPerformancedrawal(0);
      setPerformanceFeeAdminShare(0);
      setPerformanceFeeLiquidityShare(0);
      setPerformanceFeeUpline(0);
      setSnackBar(true);
    }
    setEditPerformanceFee(false);
  };

  const updateReferralCommissionFee = async () => {
    try {
      const signer = await library.getSigner();
      const contract = new ethers.Contract(contractAddress, abi, signer);
      // const amount = ethers.utils.parseEther(`${amountToWithdraw}`)

      const txResponse = await contract.changeReferralCommissionFee(
        rfCFee * 100,
        rfCFeeAdminShare * 100,
        rfCFeeLiquidityShare * 100
      );

      setMessage(
        <p>
          {" "}
          Transaction initiated.{" "}
          <a
            href={`https://bscscan.com/tx/${txResponse.hash}`}
            target="_blank"
            rel="noreferrer"
          >
            View
          </a>{" "}
        </p>
      );
      setSnackBarStyle("snackBarGreen");

      setSnackBar(true);

      const txReceipt = await txResponse.wait();
      setMessage("Referral Commissions updates");
      setRFCFee(0);
      setRFCFeeAdminShare(0);
      setRFCFeeLiquidityShare(0);

      setSnackBar(true);
    } catch (e) {
      if (e.reason === undefined) setMessage(e.message);
      else setMessage(e.reason);
      setSnackBarStyle("snackBarRed");

      setRFCFee(0);
      setRFCFeeAdminShare(0);
      setRFCFeeLiquidityShare(0);
      setSnackBar(true);
    }

    setEditRFCFee(false);
  };

  const updateEarningRates = async () => {
    try {
      const signer = await library.getSigner();
      const contract = new ethers.Contract(contractAddress, abi, signer);

      const txResponse = await contract.ChangeEarningsRate(
        earningRateA * 100,
        earningRateB * 100,
        earningRateC * 100
      );

      setMessage(
        <p>
          {" "}
          Transaction initiated.{" "}
          <a
            href={`https://bscscan.com/tx/${txResponse.hash}`}
            target="_blank"
            rel="noreferrer"
          >
            View
          </a>{" "}
        </p>
      );
      setSnackBarStyle("snackBarGreen");

      setSnackBar(true);

      const txReceipt = await txResponse.wait();

      setEarningRateA(0);
      setEarningRateB(0);
      setEarningRateC(0);

      setMessage("Earning rates updated");
      setSnackBar(true);
    } catch (e) {
      setSnackBarStyle("snackBarRed");

      if (e.reason === undefined) setMessage(e.message);
      else setMessage(e.reason);

      setEarningRateA(0);
      setEarningRateB(0);
      setEarningRateC(0);
      setSnackBar(true);
    }

    setEditEarningRates(false);
  };

  const updateMinimumStake = async () => {
    try {
      const signer = await library.getSigner();
      const contract = new ethers.Contract(contractAddress, abi, signer);
      const amount = ethers.utils.parseEther(`${minStake}`);
      const txResponse = await contract.ChangeMinStake(amount);

      setMessage(
        <p>
          {" "}
          Transaction initiated.{" "}
          <a
            href={`https://bscscan.com/tx/${txResponse.hash}`}
            target="_blank"
            rel="noreferrer"
          >
            View
          </a>{" "}
        </p>
      );
      setSnackBarStyle("snackBarGreen");

      setSnackBar(true);

      const txReceipt = await txResponse.wait();

      setMinStake(0);
      setMessage("Minimum stake amount updated");
      setSnackBar(true);
    } catch (e) {
      if (e.reason === undefined) setMessage(e.message);
      else setMessage(e.reason);
      setSnackBarStyle("snackBarRed");

      setMinStake(0);
      setSnackBar(true);
    }

    setEditMinStake(false);
  };

  const updateMaximumStake = async () => {
    try {
      const signer = await library.getSigner();
      const contract = new ethers.Contract(contractAddress, abi, signer);
      const amount = ethers.utils.parseEther(`${maxStake}`);

      const txResponse = await contract.ChangeMaxStake(amount);

      setMessage(
        <p>
          {" "}
          Transaction initiated.{" "}
          <a
            href={`https://bscscan.com/tx/${txResponse.hash}`}
            target="_blank"
            rel="noreferrer"
          >
            View
          </a>{" "}
        </p>
      );
      setSnackBarStyle("snackBarGreen");

      setSnackBar(true);

      const txReceipt = await txResponse.wait();

      setMaxStake(0);
      setMessage("Maximum stake amount updated");
      setSnackBar(true);
    } catch (e) {
      setSnackBarStyle("snackBarRed");

      if (e.reason === undefined) setMessage(e.message);
      else setMessage(e.reason);

      setMaxStake(0);

      setSnackBar(true);
    }

    setEditMaxStake(false);
  };

  const pauseContract = async () => {
    try {
      const signer = await library.getSigner();
      const contract = new ethers.Contract(contractAddress, abi, signer);

      const txResponse = await contract.pauseContract();
      toggleStatusVisibility();
      setMessage(
        <p>
          {" "}
          Transaction initiated.{" "}
          <a
            href={`https://bscscan.com/tx/${txResponse.hash}`}
            target="_blank"
            rel="noreferrer"
          >
            View
          </a>{" "}
        </p>
      );
      setSnackBarStyle("snackBarGreen");

      setSnackBar(true);

      const txReceipt = await txResponse.wait();
      setMessage("Project Paused");
      setSnackBar(true);
    } catch (e) {
      toggleStatusVisibility();

      if (e.reason === undefined) setMessage(e.message);
      else setMessage(e.reason);
      setSnackBarStyle("snackBarRed");

      setSnackBar(true);
    }
  };

  const resumeContract = async () => {
    try {
      const signer = await library.getSigner();
      const contract = new ethers.Contract(contractAddress, abi, signer);

      const txResponse = await contract.resumeContract();

      toggleStatusVisibility();
      setMessage(
        <p>
          {" "}
          Transaction initiated.{" "}
          <a
            href={`https://bscscan.com/tx/${txResponse.hash}`}
            target="_blank"
            rel="noreferrer"
          >
            View
          </a>{" "}
        </p>
      );
      setSnackBarStyle("snackBarGreen");

      setSnackBar(true);

      const txReceipt = await txResponse.wait();
      setMessage("Project Resumed");

      setSnackBar(true);
    } catch (e) {
      toggleStatusVisibility();
      setSnackBarStyle("snackBarRed");

      if (e.reason === undefined) setMessage(e.message);
      else setMessage(e.reason);

      setSnackBar(true);
    }
  };

  const suspend = async () => {
    try {
      const signer = await library.getSigner();
      const contract = new ethers.Contract(contractAddress, abi, signer);
      const txResponse = await contract.suspendUser(actionUser?.toString());

      toggleSuspendVisibility();
      setMessage(
        <p>
          {" "}
          Transaction initiated.{" "}
          <a
            href={`https://bscscan.com/tx/${txResponse.hash}`}
            target="_blank"
            rel="noreferrer"
          >
            View
          </a>{" "}
        </p>
      );
      setSnackBarStyle("snackBarGreen");

      setSnackBar(true);

      const txReceipt = await txResponse.wait();

      setMessage(`${actionUser} suspended`);

      setActionUser("");
      setSnackBar(true);
    } catch (e) {
      toggleSuspendVisibility();
      setSnackBarStyle("snackBarRed");

      if (e.reason === undefined) setMessage(e.message);
      else setMessage(e.reason);

      setSnackBar(true);
    }
  };

  const reinstate = async () => {
    try {
      const signer = await library.getSigner();
      const contract = new ethers.Contract(contractAddress, abi, signer);
      const txResponse = await contract.reinstateUser(actionUser?.toString());

      toggleSuspendVisibility();
      setMessage(
        <p>
          {" "}
          Transaction initiated.{" "}
          <a
            href={`https://bscscan.com/tx/${txResponse.hash}`}
            target="_blank"
            rel="noreferrer"
          >
            View
          </a>{" "}
        </p>
      );
      setSnackBarStyle("snackBarGreen");

      setSnackBar(true);

      const txReceipt = await txResponse.wait();
      setMessage(`${actionUser} reinstated`);

      setActionUser("");

      setSnackBar(true);
    } catch (e) {
      toggleSuspendVisibility();
      setSnackBarStyle("snackBarRed");

      if (e.reason === undefined) setMessage(e.message);
      else setMessage(e.reason);

      setActionUser("");
      setSnackBar(true);
    }
  };

  const transferOwnership = async () => {
    try {
      const signer = await library.getSigner();
      const contract = new ethers.Contract(contractAddress, abi, signer);
      const txResponse = await contract.transferOwnership(
        actionUser?.toString()
      );

      setSnackBarStyle("snackBarGreen");

      toggleMiscellaneousVisibility();
      setMessage(
        <p>
          {" "}
          Transaction initiated.{" "}
          <a
            href={`https://bscscan.com/tx/${txResponse.hash}`}
            target="_blank"
            rel="noreferrer"
          >
            View
          </a>{" "}
        </p>
      );
      setSnackBar(true);

      const txReceipt = await txResponse.wait();
      setMessage("Ownership transfer inititated");
      setActionUser("");
      setSnackBar(true);
    } catch (e) {
      toggleMiscellaneousVisibility();
      setSnackBarStyle("snackBarRed");

      if (e.reason === undefined) setMessage(e.message);
      else setMessage(e.reason);

      setSnackBar(true);
    }
  };

  const AddLiquidity = async () => {
    try {
      const signer = library.getSigner();
      const contract = new ethers.Contract(contractAddress, abi, signer);
      const options = { value: ethers.utils.parseEther(`${amountToStake}`) };

      const txResponse = await contract.AddLiquidity(options);

      toggleMiscellaneousVisibility();
      setMessage(
        <p>
          {" "}
          Transaction initiated.{" "}
          <a
            href={`https://bscscan.com/tx/${txResponse.hash}`}
            target="_blank"
            rel="noreferrer"
          >
            View
          </a>{" "}
        </p>
      );
      setSnackBarStyle("snackBarGreen");

      setSnackBar(true);

      const txReceipt = await txResponse.wait();
      setMessage(`${amountToStake} BNB added to liquidity`);

      setAmountToStake(0);
      setSnackBar(true);
    } catch (e) {
      toggleMiscellaneousVisibility();

      if (e.reason === undefined) setMessage(e.message);
      else setMessage(e.reason);
      setSnackBarStyle("snackBarRed");

      setAmountToStake(0);
      setSnackBar(true);
    }
  };

  const acceptOwnerShip = async () => {
    try {
      const signer = await library.getSigner();
      const contract = new ethers.Contract(contractAddress, abi, signer);
      setSnackBarStyle("snackBarGreen");

      const txResponse = await contract.acceptOwnerShip();

      setMessage(
        <p>
          {" "}
          Transaction initiated.{" "}
          <a
            href={`https://bscscan.com/tx/${txResponse.hash}`}
            target="_blank"
            rel="noreferrer"
          >
            View
          </a>{" "}
        </p>
      );
      setSnackBar(true);

      const txReceipt = await txResponse.wait();
      setMessage("Ownership transfer complete");
      setNextOwner(false);
      checkAdmin();
      setSnackBar(true);
    } catch (e) {
      setNextOwner(false);

      setSnackBarStyle("snackBarRed");

      if (e.reason === undefined) setMessage(e.message);
      else setMessage(e.reason);

      setSnackBar(true);
    }
  };

  const renounceOwnerShip = async () => {
    try {
      const signer = await library.getSigner();
      const contract = new ethers.Contract(contractAddress, abi, signer);

      const txResponse = await contract.renounceOwnerShip();
      setSnackBarStyle("snackBarGreen");

      toggleMiscellaneousVisibility();
      setMessage(
        <p>
          {" "}
          Transaction initiated.{" "}
          <a
            href={`https://bscscan.com/tx/${txResponse.hash}`}
            target="_blank"
            rel="noreferrer"
          >
            View
          </a>{" "}
        </p>
      );
      setSnackBar(true);

      const txReceipt = await txResponse.wait();

      setMessage("Ownership renounced");
      setSnackBar(true);
    } catch (e) {
      toggleMiscellaneousVisibility();
      setSnackBarStyle("snackBarRed");

      if (e.reason === undefined) setMessage(e.message);
      else setMessage(e.reason);

      setSnackBar(true);
    }
  };

  const updateBasicInfo = async () => {
    const contract = new ethers.Contract(contractAddress, abi, library);
    const depositFee = await contract.depositFee();
    const withdrawalFee = await contract.withdrawalFee();
    const minStake = await contract.minimumStakeValue();
    const maxStake = await contract.maximumStakeValue();
    setDepositFeeInfo({
      fee: (parseFloat(depositFee["fee"]?.toString()) / 100).toFixed(2),
      adminShare: (
        parseFloat(depositFee["adminShare"]?.toString()) / 100
      ).toFixed(2),
      liquidty: (parseFloat(depositFee["liquidity"]?.toString()) / 100).toFixed(
        2
      ),
    });

    setWithdrawalFeeInfo({
      fee: (parseFloat(withdrawalFee["fee"]?.toString()) / 100).toFixed(2),
      adminShare: (
        parseFloat(withdrawalFee["adminShare"]?.toString()) / 100
      ).toFixed(2),
      liquidty: (
        parseFloat(withdrawalFee["liquidity"]?.toString()) / 100
      ).toFixed(2),
      directReferee: (
        parseFloat(withdrawalFee["directSponsor"]?.toString()) / 100
      ).toFixed(2),
    });

    setMinimumStakeInfo(ethers.utils.formatEther(minStake?.toString()));

    setMaximumStakeInfo(ethers.utils.formatEther(maxStake?.toString()));
  };

  const updateAdminInfo = async () => {
    // Return if not admin
    // if (account?.toLowerCase() !== owner?.toLowerCase()) {
    //     return;
    // }
    setShowLoading(true);
    const contract = new ethers.Contract(contractAddress, abi, library);

    var totalBNBStaked = parseFloat(
      ethers.utils.formatEther((await contract.totalBNBStaked())?.toString())
    );
    var totalBNBWithdrawan = parseFloat(
      ethers.utils.formatEther((await contract.totalBNBWithdrawan())?.toString())
    );

    totalBNBStaked = totalBNBStaked.toFixed(5)
    totalBNBWithdrawan = totalBNBWithdrawan.toFixed(5)

    var totalBNBWithdrawanAfterDeduction = parseFloat(
      ethers.utils.formatEther((await contract.totalBNBWithdrawanAfterDeduction())?.toString())
    );

    const totalFeePaid = parseFloat(
      ethers.utils.formatEther((await contract.totalFeePaid())?.toString())
    ).toFixed(5);
    const startTime = await contract.startTime();

    const volumeLocked = parseFloat(
      ethers.utils.formatEther(
        (await library.getBalance(contractAddress))?.toString()
      )
    ).toFixed(5);

    // const depositFee = await contract.depositFee();
    const performanceFee = await contract.performanceFee();
    const RCF = await contract.referralCommissionFee();
    // const withdrawalFee = await contract.withdrawalFee();

    const now = new Date();
    const creationDate = new Date(startTime * 1000);
        
    const diff = dateDifference(creationDate, now);

    const dailyEarningRate_A = await contract.dailyEarnings_A();
    const dailyEarningRate_B = await contract.dailyEarnings_B();
    const dailyEarningRate_C = await contract.dailyEarnings_C();

    // const minStake = await contract.minimumStakeValue();
    // const maxStake = await contract.maximumStakeValue();

    const AdminEarnings = await contract.viewAdminEarnings();

    // setDepositFeeInfo({
    //     fee: (parseFloat(depositFee["fee"]?.toString()) / 100).toFixed(2),
    //     adminShare: (parseFloat(depositFee["adminShare"]?.toString()) / 100).toFixed(2),
    //     liquidty: (parseFloat(depositFee["liquidity"]?.toString()) / 100).toFixed(2)
    // })

    // setWithdrawalFeeInfo({
    //     fee: (parseFloat(withdrawalFee["fee"]?.toString()) / 100).toFixed(2),
    //     adminShare: (parseFloat(withdrawalFee["adminShare"]?.toString()) / 100).toFixed(2),
    //     liquidty: (parseFloat(withdrawalFee["liquidity"]?.toString()) / 100).toFixed(2),
    //     directReferee: (parseFloat(withdrawalFee["directSponsor"]?.toString()) / 100).toFixed(2)
    // })

    setRCFInfo({
      fee: (parseFloat(RCF["fee"]?.toString()) / 100).toFixed(2),
      adminShare: (parseFloat(RCF["adminShare"]?.toString()) / 100).toFixed(2),
      liquidty: (parseFloat(RCF["liquidity"]?.toString()) / 100).toFixed(2),
    });

    setPerformanceFeeInfo({
      fee: (parseFloat(performanceFee["fee"]?.toString()) / 100).toFixed(2),
      adminShare: (
        parseFloat(performanceFee["adminShare"]?.toString()) / 100
      ).toFixed(2),
      liquidty: (
        parseFloat(performanceFee["liquidity"]?.toString()) / 100
      ).toFixed(2),
      upline: (parseFloat(performanceFee["upline"]?.toString()) / 100).toFixed(
        2
      ),
    });

    setEarningRatesInfo({
      A: (parseFloat(dailyEarningRate_A?.toString()) / 100).toFixed(2),
      B: (parseFloat(dailyEarningRate_B?.toString()) / 100).toFixed(2),
      C: (parseFloat(dailyEarningRate_C?.toString()) / 100).toFixed(2),
    });

    // setMinimumStakeInfo(
    //     ethers.utils.formatEther(minStake?.toString())
    // )

    // setMaximumStakeInfo(
    //     ethers.utils.formatEther(maxStake?.toString())
    // )

    await updateBasicInfo();
    UpdateAdminDets({
      /*member: totalMembers,*/
      balance: parseFloat(
        ethers.utils.formatEther(AdminEarnings["currentBalance"]?.toString())
      ).toFixed(10),
      totalEarnings: parseFloat(
        ethers.utils.formatEther(AdminEarnings["totalEarnings"]?.toString())
      ).toFixed(5),
      bnbStaked: totalBNBStaked,
      volumeLocked: volumeLocked,
      reserveBalance: (
        (parseFloat(volumeLocked) - parseFloat(totalBNBStaked)) * 0.75
      ).toFixed(5),
      bnbWithdrawan: totalBNBWithdrawanAfterDeduction,
      runningTime: diff,
      fees: totalFeePaid,
      teamSize: AdminEarnings["teamSize"]?.toString(),
      downline: parseFloat(
        ethers.utils.formatEther(AdminEarnings["downlineBalance"]?.toString())
      ).toFixed(5),
      depositFee: parseFloat(
        ethers.utils.formatEther(AdminEarnings["depositFees"]?.toString())
      ).toFixed(5),
      withdrawalFee: parseFloat(
        ethers.utils.formatEther(AdminEarnings["withdrawFees"]?.toString())
      ).toFixed(5),
      referralFee: parseFloat(
        ethers.utils.formatEther(AdminEarnings["referralFees"]?.toString())
      ).toFixed(5),
      performanceFee: parseFloat(
        ethers.utils.formatEther(
          AdminEarnings["performanceFeeCommissions"]?.toString()
        )
      ).toFixed(5),
      residualCommissions: parseFloat(
        ethers.utils.formatEther(
          AdminEarnings["residualCommissions"]?.toString()
        )
      ).toFixed(5),
      teamEarning: parseFloat(
        ethers.utils.formatEther(AdminEarnings["teamEarnings"]?.toString())
      ).toFixed(5),
    });

    setShowLoading(false);
  };

  const updateUserInfo = async () => {
    if (account?.toString().toLowerCase() === admin?.toString().toLowerCase())
      return;
    try {
      setShowLoading(true);
      await updateBasicInfo();

      const contract = new ethers.Contract(contractAddress, abi, library);
      const userBalance = (
        await contract.viewUserBalance(account?.toString())
      )?.toString();
      const value = parseFloat(ethers.utils.formatEther(userBalance)).toFixed(
        5
      );
      var valueTo12 = parseFloat(
        ethers.utils.formatEther(userBalance)
      ).toFixed(12);
      const stakeDetails = await contract.viewUserStake(account?.toString());
      const isActive = stakeDetails["isActive"];
      const isRegistered = await contract.checkUserRegistry(account?.toString());

      setMaxed(stakeDetails["maxed"]);

      const userDetails = await contract.viewUserDetails(account?.toString());
      console.log(userDetails.teamsize[1]?.toString())

      let withdrawAmount = parseFloat(valueTo12);

      const withdrawalFeeInfo = await contract.withdrawalFee();
      let withdrawalFeePercent = (parseFloat(withdrawalFeeInfo["fee"]?.toString()) / 100).toFixed(2)

      let withdrawalFee = 
        parseFloat(withdrawAmount) * (parseFloat(withdrawalFeePercent) / 100);
      withdrawalFee = isNaN(withdrawalFee) ? 0 : withdrawalFee;
      let antiWhaleTax = parseFloat(await calculateAntiWhaleTax(withdrawAmount?.toString()));
      antiWhaleTax = isNaN(antiWhaleTax) ? 0 : antiWhaleTax;
      let withdrawAfterFeeAmount = (
        parseFloat(withdrawAmount) -
        (withdrawalFee + antiWhaleTax)
      ).toFixed(5);
      withdrawAfterFeeAmount = isNaN(withdrawAfterFeeAmount)
        ? 0
        : withdrawAfterFeeAmount;
      setWhaleTaxAmount(parseFloat(antiWhaleTax).toFixed(5));
      setWithdrawFee(parseFloat(withdrawalFee).toFixed(5));
      setWithdrawAfterFee(withdrawAfterFeeAmount);

      setWithdrawalAmount(withdrawAmount)

      
      const downlineLevelStakedAmount = userDetails[
        "downlineLevelActiveStakes"
      ].map((item) => {
        return parseFloat(ethers.utils.formatEther(item?.toString())).toFixed(5);
      });
      const downline = downlineLevelStakedAmount.reduce((acc, num) => acc + parseFloat(num), 0)
      console.log(downline)

      const withdrawalrequestedAmounts = userDetails[
        "withdrawalrequestedAmount"
      ].map((item) => {
        return parseFloat(ethers.utils.formatEther(item?.toString())).toFixed(5);
      });

      const withdrawalAmountRecieved = userDetails[
        "withdrawalAmountRecieved"
      ].map((item) => {
        return parseFloat(ethers.utils.formatEther(item?.toString())).toFixed(5);
      });

      const withdrawalTime = userDetails["withdrawalTime"].map((item) => {
        const d = new Date(parseInt(item?.toString()) * 1000);
        return d.toLocaleDateString({
          dateStyle: "short",
        });
      });

      let userWithdrawHistory = [];

      const cycles = userDetails["cycles"]?.toString();

      let AllTimeBNB = parseFloat(
        ethers.utils.formatEther(userDetails["previousEarnings"]?.toString())
      ).toFixed(5);

      for (let i = withdrawalTime.length - 1; i >= 0; i--) {
        userWithdrawHistory.push({
          date: withdrawalTime[i],
          amountRequested: parseFloat(withdrawalrequestedAmounts[i]).toFixed(7),
          amountRecieved: parseFloat(withdrawalAmountRecieved[i]).toFixed(7),
        });
      }

      const TeamEarning = parseFloat(
        ethers.utils.formatEther(userDetails["teamEarnings"]?.toString())
      );

      let allTimeProgress = (parseInt(cycles) - 1) * 250;
      if (allTimeProgress < 0) {
        allTimeProgress = 0;
      }
      setUserWithdrawHistory(userWithdrawHistory);

      UpdateUserDets({
        ...userDets,
        balance: value?.toString(),
        balanceFull: valueTo12?.toString(),
        isRegistered: isRegistered,
        downline: downline,
        downlineStakeInfo: downlineLevelStakedAmount,
        TeamEarning: TeamEarning.toFixed(7)
      });

      if (isActive) {
        const stakedAmount = parseFloat(
          ethers.utils.formatEther(stakeDetails["stakedAmount"]?.toString())
        ).toFixed(5);
        const activeStake = parseFloat(
          ethers.utils.formatEther(stakeDetails["activeStake"]?.toString())
        ).toFixed(5);
        const creationTime = stakeDetails["creationTime"]?.toString();

        const currentEarning = parseFloat(
          ethers.utils.formatEther(stakeDetails["totalEarnings"]?.toString())
        ).toFixed(5);

        const maxEarning = parseFloat((activeStake * 250) / 100).toFixed(7);

        let progress = ((currentEarning * 250) / maxEarning).toFixed(3);

        if (progress >= 250) {
          setMaxed(true);
          progress = 250;
        }

        const dailyEarningRate =
          parseFloat(stakeDetails["dailyEarningRate"]?.toString()) / 100;

        const missedReferralEarnings = stakeDetails["missedReferralEarnings"];
        const referralEarning = stakeDetails["referralEarning"];

        var totalReferralEarning = 0;

        const residualCommissions = parseFloat(
          ethers.utils.formatEther(
            stakeDetails["totalResidualCommission"]?.toString()
          )
        );
        const withdrawalCommission = parseFloat(
          ethers.utils.formatEther(
            stakeDetails["totalWithdrawalCommission"]?.toString()
          )
        );
        const referralCommission = parseFloat(
          ethers.utils.formatEther(
            stakeDetails["totalReferralCommission"]?.toString()
          )
        );


        const maxReferralLevel = parseInt(
          stakeDetails["maxReferralLevel"]?.toString()
        );
        const stakeAmountHistory = stakeDetails["stakeAmountHistory"].map(
          (item) => {
            return parseFloat(
              ethers.utils.formatEther(item?.toString())
            ).toFixed(5);
          }
        );
        const stakeTimeHistory = stakeDetails["stakeTimeHistory"].map(
          (item) => {
            const d = new Date(parseInt(item?.toString()) * 1000);
            return d.toLocaleDateString({
              dateStyle: "short",
            });
          }
        );

        let stakeHistory = [];

        for (let i = stakeTimeHistory.length - 1; i >= 0; i--) {
          stakeHistory.push({
            date: stakeTimeHistory[i],
            amount: parseFloat(stakeAmountHistory[i]).toFixed(7),
          });
        }
        setUserFuelHistoy(stakeHistory);
        const levelEarning = [0, 0, 0, 0, 0];

        let i;
        for (i = 0; i <= parseInt(maxReferralLevel); i++) {
          levelEarning[i] = parseFloat(
            ethers.utils.formatEther(referralEarning[i]?.toString())
          ).toFixed(7);
          totalReferralEarning += parseFloat(levelEarning[i]);
        }
        levelEarning[0] = (parseFloat(levelEarning[0]) + parseFloat(withdrawalCommission)).toFixed(7)
        
        
        for (; i < 5; i++) {
          levelEarning[i] = parseFloat(
            ethers.utils.formatEther(missedReferralEarnings[i]?.toString())
          ).toFixed(7);
        }
        

        const dailyEarnings = parseFloat(
          ethers.utils.formatEther(
            stakeDetails["dailyEarningsBeforeFee"]?.toString()
          )
        ).toFixed(5);

        /*const TeamEarning = (
          parseFloat(referralCommission) +
          parseFloat(withdrawalCommission) +
          parseFloat(residualCommissions)
        ).toFixed(5);*/
        
        console.log(referralCommission)
        console.log(withdrawalCommission)
        console.log(TeamEarning)

        const currentTeamEarnings = parseFloat(referralCommission) + parseFloat(withdrawalCommission) + parseFloat(residualCommissions);
        const newTeamEarnings = parseFloat(TeamEarning) + currentTeamEarnings

        
        const teamSize = (await contract.seeTeamSize(account?.toString()))
          ?.toString()
          .split(",");
          console.log(teamSize)
        let totalTeamSize = 0;
        for (let i = 0; i < 5; i++) {
          totalTeamSize += parseInt(teamSize[i]);
        }
        const now = new Date();
        const creationDate = new Date(creationTime * 1000);

        const diff = dateDifference(creationDate, now);

        const passiveEarning = (
          parseFloat(currentEarning) - parseFloat((
            parseFloat(referralCommission) +
            parseFloat(withdrawalCommission) +
            parseFloat(residualCommissions)
          ).toFixed(5))
        ).toFixed(5);

        if (isNaN(progress)) {
          progress = 0;
        }
        const progressIn100 = parseInt((parseFloat(progress) * 73) / 250);
        allTimeProgress = parseFloat(allTimeProgress) + parseFloat(progress);

        AllTimeBNB = (
          parseFloat(AllTimeBNB) + parseFloat(currentEarning)
        ).toFixed(5);
        console.log("IN IF");
        UpdateUserDets({
          ...userDets,
          balance: value?.toString(),
          balanceFull: valueTo12,
          isRegistered: isRegistered,
          downline: downline,
          downlineStakeInfo: downlineLevelStakedAmount,
          activeStakePresent: isActive,
          activeStake: activeStake,
          timepassed: diff,
          currentEarning: currentEarning,
          maxEarning: maxEarning,
          dailyEarningRate: dailyEarningRate,
          dailyEarnings: dailyEarnings,
          passiveEarning: passiveEarning,
          EarningsByLevel: levelEarning,
          teamSize: teamSize,
          progress: progress,
          maxReferralLevel: maxReferralLevel,
          totalTeamSize: totalTeamSize,
          totalReferralEarning: totalReferralEarning,
          AllTimeBNB: AllTimeBNB,
          cycles: cycles,
          AllTimeProgress: allTimeProgress,
          progressin100: progressIn100,
          TeamEarning: newTeamEarnings.toFixed(7),
          currentTeamEarning: currentTeamEarnings.toFixed(5)
        });
      } else {
        console.log("IN ELSE");
        UpdateUserDets({
          ...userDets,
          balance: value?.toString(),
          balanceFull: valueTo12,
          activeStakePresent: isActive,
          activeStake: 0,
          timepassed: 0,
          currentEarning: 0,
          maxEarning: 0,
          dailyEarningRate: 0,
          dailyEarnings: 0,
          passiveEarning: 0,
          TeamEarning: 0,
          teamSize: [0, 0, 0, 0, 0],
          progress: 0,
          EarningsByLevel: [0, 0, 0, 0, 0],
          maxReferralLevel: 5,
          isRegistered: isRegistered,
          downline: downline,
          totalReferralEarning: 0,
          downlineStakeInfo: [0, 0, 0, 0, 0],
          cycles: cycles,
          AllTimeBNB: AllTimeBNB,
          AllTimeProgress: allTimeProgress,
          progressin100: 0,
        });
      }
      setShowLoading(false);
    } catch (e) {
      console.log("error caught in update user Info", e);
      setShowLoading(false);
    }



  };

  const handleClose = (event, reason, key) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackBar(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const calculateAntiWhaleTax = async (_amount) => {
    //var contractCurrentBalance = parseFloat(contractBalance);
    var contractCurrentBalance = (await library.getBalance(contractAddress))?.toString();
    contractCurrentBalance = parseFloat(ethers.utils.formatEther((contractCurrentBalance)?.toString())).toFixed(12);

    const contract = new ethers.Contract(contractAddress, abi, library);
    _amount = ethers.utils.parseEther((parseFloat(_amount).toFixed(12))?.toString())
    var tax = await contract.AntiWhaleTax(_amount);
    tax = ethers.utils.formatEther(tax?.toString())

    console.log("Tax: " + tax)
    console.log("Contract Balance: " + contractCurrentBalance)

    /*
    var relativePercentage = (_amount * 100) / (contractCurrentBalance);

    if (relativePercentage < 5) {
        return 0;
    }
    else if (relativePercentage > 14) {
        relativePercentage = 14;
    } 

    var taxPercentage = 5 * (relativePercentage - 4) * 100;

    var tax = (_amount * taxPercentage) / 10000;
    */

    return tax;
  };

  /*const handleWithdrawalInput = (e) => {
    setAmountToWithdraw(e.target.value);
    if (parseFloat(e.target.value) > parseFloat(userDets.max24HourWithdrawal)) {
      set24hourError(true);
    } else {
      set24hourError(false);
    }
    let withdrawalFee =
      parseFloat(e.target.value) * (parseFloat(withdrawalFeeInfo.fee) / 100);
    withdrawalFee = isNaN(withdrawalFee) ? 0 : withdrawalFee;
    let antiWhaleTax = calculateAntiWhaleTax(e.target.value);
    antiWhaleTax = isNaN(antiWhaleTax) ? 0 : antiWhaleTax;
    let withdrawAfterFeeAmount = (
      parseFloat(e.target.value) -
      (withdrawalFee + antiWhaleTax)
    ).toFixed(5);
    withdrawAfterFeeAmount = isNaN(withdrawAfterFeeAmount)
      ? 0
      : withdrawAfterFeeAmount;
    setWhaleTaxAmount(parseFloat(antiWhaleTax).toFixed(5));
    setWithdrawFee(parseFloat(withdrawalFee).toFixed(5));
    setWithdrawAfterFee(withdrawAfterFeeAmount);

    if (parseFloat(e.target.value) > parseFloat(userDets.balanceFull)) {
      setMaxWithdrawalError(true);
    } else {
      setMaxWithdrawalError(false);
    }
  };*/

  useEffect(() => {
    if (provider?.on) {
      const handleAccountsChanged = (accounts) => {
        if (accounts.length === 0) {
          UpdateUserDets({
            ...userDets,
            balance: 0,
            balanceFull: 0,
            activeStakePresent: false,
            activeStake: 0,
            timepassed: 0,
            currentEarning: 0,
            maxEarning: 0,
            dailyEarningRate: 0,
            dailyEarnings: 0,
            passiveEarning: 0,
            TeamEarning: 0,
            teamSize: [0, 0, 0, 0, 0],
            progress: 0,
            EarningsByLevel: [0, 0, 0, 0, 0],
            maxReferralLevel: 5,
            isRegistered: false,
            downline: 0,
            totalReferralEarning: 0,
            downlineStakeInfo: [0, 0, 0, 0, 0],
          });
          UpdateAdminDets({
            member: 0,
            balance: 0,
            totalEarnings: 0,
            bnbStaked: 0,
            volumeLocked: 0,
            reserveBalance: 0,
            bnbWithdrawan: 0,
            runningTime: 0,
            fees: 0,
            teamSize: 0,
            downline: 0,
            depositFee: 0,
            withdrawalFee: 0,
            referralFee: 0,
            performanceFee: 0,
            residualCommissions: 0,
            teamEarning: 0,
          });
        }
        setAccount(accounts[0]);
      };

      const handleChainChanged = (chainId) => {
        console.log(chainId, "CURRENT CHAINT UD");
        if (chainId !== "0x38" || chainId !== "56") {
          setMessage("Incorrect Network 2: Switch to BSC network" + chainId);
          setSnackBarStyle("snackBarRed");
          setSnackBar(true);
        }
      };

      const handleDisconnect = () => {
        console.log("Disconnecting");

        disconnect();
      };

      provider?.on("accountsChanged", handleAccountsChanged);
      //provider?.on("chainChanged", handleChainChanged);
      provider?.on("disconnect", handleDisconnect);

      return () => {
        if (provider?.removeListener) {
          provider?.removeListener("accountsChanged", handleAccountsChanged);
          provider?.removeListener("chainChanged", handleChainChanged);
          provider?.removeListener("disconnect", handleDisconnect);
        }
      };
    }
  }, [provider]);

  useEffect(() => {
    checkReferee();
  }, [library]);

  var intervalId; // Global

  // useEffect(() => {

  //     intervalId = setInterval(async () => {
  //         if (account !== undefined) {
  //             await updateUserInfo();
  //             await updateAdminInfo();
  //         }
  //     }, 15 * 1000)
  //     return () => clearInterval(intervalId);
  // }, []);

  // Test

  useEffect(() => {
    console.log("here ->", provider?.chainId);
    updateUserInfo();
    
    if (provider?.chainId) {
      if (provider?.chainId === "56" || provider?.chainId === "0x38") {
        clearInterval(intervalId);
        console.log("++++++++++++++++++++++++++++++++++++++");
  
        checkReferee();
        checkAdmin();
        updateUserInfo();
      }
      else {
          setMessage(
            "Incorrect Network 1: Switch to BSC network - " + provider?.chainId
          );
          setSnackBarStyle("snackBarRed");
          setSnackBar(true);
      }
      
      
    }
    
  }, [provider, account, library]);

  return (
    <>
      {showLoading && (
        <Loading>
          <img src={loading} alt="loading" />
        </Loading>
      )}

      <Navbar
        connectWallet={connectWallet}
        disconnect={disconnect}
        account={account}
        active={account !== undefined}
      />
      {nextOwner && (
        <NextOwner>
          <Button
            className="acceptOwnerShip"
            onClick={acceptOwnerShip}
            variant="contained"
          >
            Accept Project Ownership
          </Button>
        </NextOwner>
      )}

      <Snackbar
        open={snackBarOpen}
        autoHideDuration={3000}
        onClose={handleClose}
        message={message}
        action={action}
        className={snackBarStyle}
      />
      <GrowthEngineContainer>
        {teamLevelEarnings && (
          <PopupContainer>
            <ClickAwayListener
              onClickAway={() => setShowTeamLevelEarnings(false)}
            >
              <Popup>
                <div style={{ minHeight: "10px" }} className="logoContainer">
                  <CloseIcon
                    onClick={() => {
                      toggleTeamLevelEarningVisibility();
                    }}
                  />
                </div>
                <img src={teamearnings} alt="teamEarnings" />
                <h3>TEAM EARNINGS.</h3>

                <TeamEarning>
                  <table>
                    <thead>
                      <HistoryRow>
                        <th className="transparent text-left">Level</th>
                        <th className="transparent text-left">Amount Earned</th>
                      </HistoryRow>
                    </thead>

                    <tbody>
                      {userDets.EarningsByLevel.map((item, index) => (
                        <HistoryRow>
                          <td className="noBox">
                            <strong>{index + 1}</strong>
                          </td>
                          <td
                            className={
                              index <= userDets.maxReferralLevel
                                ? "noBox green"
                                : "noBox red"
                            }
                          >
                            {item}
                          </td>
                        </HistoryRow>
                      ))}
                    </tbody>
                  </table>
                </TeamEarning>

                <p className="green">
                  Total Amount Earned :{" "}
                  <strong>
                    {parseFloat(userDets.TeamEarning).toFixed(7)}
                  </strong>{" "}
                  BNB{" "}
                </p>

                <p>
                  Your earnings above include commissions & bonuses from members
                  in your team by level
                </p>
              </Popup>
            </ClickAwayListener>
          </PopupContainer>
        )}

        {showGrowth && (
          <PopupContainer>
            <ClickAwayListener onClickAway={() => setShowGrowth(false)}>
              <Popup>
                <div style={{ minHeight: "10px" }} className="logoContainer">
                  <CloseIcon onClick={toggleGrowthVisibility} />
                </div>
                <img src={passiveEarningIcon} alt="passiveEarnings" />
                <h3>Growth</h3>

                <TeamInfo>
                  <TeamInfoItem className="passive">
                    <p>ACTIVE STAKE</p>
                    <h5 className="green">{userDets.activeStake} BNB</h5>
                    <p className="iconContainer">
                      <AutorenewOutlinedIcon /> CYCLE {userDets.cycles}
                    </p>
                  </TeamInfoItem>

                  <TeamInfoItem className="passive">
                    <p>EARNINGS</p>
                    <h5 className="green">{userDets?.currentEarning} BNB</h5>
                    <p>{userDets?.progress} %</p>
                  </TeamInfoItem>

                  <TeamInfoItem className="passive">
                    <p>ALL TIME</p>
                    <h5 className="green">{userDets?.AllTimeBNB} BNB</h5>
                    <p>{userDets?.AllTimeProgress} %</p>
                  </TeamInfoItem>
                </TeamInfo>
              </Popup>
            </ClickAwayListener>
          </PopupContainer>
        )}

        {showPassiveEarnings && (
          <PopupContainer>
            <ClickAwayListener
              onClickAway={() => setShowPassiveEarnings(false)}
            >
              <Popup>
                <div style={{ minHeight: "10px" }} className="logoContainer">
                  <CloseIcon
                    onClick={() => {
                      setShowPassiveEarnings(false);
                    }}
                  />
                </div>
                <img src={passiveEarningIcon} alt="passiveEarnings" />
                <h3>PASSIVE BNB.</h3>

                <TeamInfo>
                  <TeamInfoItem className="passive">
                    <p>ACTIVE STAKE</p>
                    <h5 className="green">{userDets.activeStake}</h5>
                    <p style={{ visibility: "hidden" }}>Text</p>
                  </TeamInfoItem>

                  <TeamInfoItem className="passive">
                    <p>DAILY EARNINGS</p>
                    <h5 className="green">{userDets.dailyEarnings} BNB</h5>
                    <p>{userDets.dailyEarningRate} %</p>
                  </TeamInfoItem>

                  <TeamInfoItem className="passive">
                    <p>
                      MAX <br />
                    </p>
                    <h5 className="green">{userDets.maxEarning} BNB</h5>
                    <p>250 %</p>
                  </TeamInfoItem>
                </TeamInfo>
              </Popup>
            </ClickAwayListener>
          </PopupContainer>
        )}

        {refereeErrorShow && (
          <PopupContainer>
            <ClickAwayListener onClickAway={() => setRefereeErrorShow(false)}>
              <FuelBox className="referrerError">
                <div style={{ minHeight: "10px" }} className="logoContainer">
                  <CloseIcon
                    onClick={() => {
                      setRefereeErrorShow(false);
                    }}
                  />
                </div>
                <WarningIcon sx={{ fontSize: 58, color: "red" }} />

                <h1 className="referrerErrorH1">INVALID REFERRAL LINK</h1>

                <p className="referrerErrorP">
                  Get back to the person who shared B.A.M with you and ask for their share link to join
                </p>

                <p className="referrerErrorP">{/*Please check and try again*/}</p>

                <p className="disclaimer">
                  {/*NOTE :If you choose continue with this invalid link, you will
                  be registered under Admin, instead of your intended referrer*/}
                  </p>
              </FuelBox>
            </ClickAwayListener>
          </PopupContainer>
        )}

        {showFuelHistory && (
          <PopupContainer>
            <ClickAwayListener onClickAway={() => setShowFuelHistory(false)}>
              <FuelBox className="fuelhistory">
                <div className="logoContainer">
                  <CloseIcon onClick={toggleShowFuelHistory} />
                  <img src={addfuel} alt="AddFuel" />
                </div>
                <h1 className="fuelhistory">FUEL HISTORY.</h1>
                <FuelHistory>
                  <table>
                    <thead>
                      <HistoryRow>
                        <th>Date</th>
                        <th>Amount</th>
                      </HistoryRow>
                    </thead>

                    <tbody>
                      {userFuelHistory.map((item) => (
                        <HistoryRow>
                          <td>{item?.date}</td>
                          <td>{item?.amount}</td>
                        </HistoryRow>
                      ))}
                    </tbody>
                  </table>
                </FuelHistory>
              </FuelBox>
            </ClickAwayListener>
          </PopupContainer>
        )}

        {showWithdrawHistory && (
          <PopupContainer>
            <ClickAwayListener
              onClickAway={() => setShowWithdrawHistory(false)}
            >
              <FuelBox className="fuelhistory">
                <div className="logoContainer">
                  <CloseIcon onClick={toggleWithdrawHistoryVisibility} />
                  <img src={withdrawhistoryIcon} alt="withdraw history" />
                </div>
                <h1 className="fuelhistory">WITHDRAW HISTORY.</h1>
                <FuelHistory>
                  <table>
                    <thead>
                      <HistoryRow>
                        <th className="withdraw">Date</th>
                        <th className="withdraw">BNB Amount</th>
                        <th className="withdraw">Amount Received</th>
                      </HistoryRow>
                    </thead>

                    <tbody>
                      {userWithdrawHistory.map((item) => (
                        <HistoryRow>
                          <td className="withdraw">{item?.date}</td>
                          <td className="withdraw">{item?.amountRequested}</td>
                          <td className="withdraw">{item?.amountRecieved}</td>
                        </HistoryRow>
                      ))}
                    </tbody>
                  </table>
                </FuelHistory>
              </FuelBox>
            </ClickAwayListener>
          </PopupContainer>
        )}

        {showFuel && (
          <PopupContainer>
            <ClickAwayListener onClickAway={() => setFuelVisibility(false)}>
              <FuelBox>
                <div className="logoContainer">
                  <CloseIcon onClick={toggleFuelVisibility} />

                  <img src={addfuel} alt="AddFuel" />
                </div>
                <h1>Stake BNB</h1>

                <p>Enter amount to stake:</p>
                <InputBox>
                  <InputContainer>
                    <input
                      value={amountToStake}
                      onChange={(e) => {
                        setAmountToStake(e.target.value);
                        console.log(
                          parseFloat(e.target.value),
                          "||||",
                          parseFloat(minimumStakeInfo)
                        );
                        if (
                          parseFloat(e.target.value) <
                          parseFloat(minimumStakeInfo)
                        ) {
                          setFuelError(true);
                          console.log("TTTTTTTTTTT");
                        } else {
                          setFuelError(false);
                          console.log("FFFFFFFFFF");
                        }
                        let stakeAfterFee = (
                          parseFloat(e.target.value) *
                          (1 - parseFloat(depositFeeInfo.fee) / 100)
                        ).toFixed(5);
                        stakeAfterFee = isNaN(stakeAfterFee)
                          ? 0
                          : stakeAfterFee;
                        setStakeAfterFee(stakeAfterFee);
                      }}
                      type="number"
                      min="0.01"
                      max="500"
                      step="any"
                    />
                    <div>
                      <img src={bnblogoWhite} alt="bnb logo" />
                      <h4>BNB</h4>
                    </div>
                  </InputContainer>

                  <h5>
                    Min Deposit: <strong>0.0125</strong>
                  </h5>
                </InputBox>

                {!fuelError && (
                  <>
                    <h5>
                      {depositFeeInfo.fee}% Deposit Fee :{" "}
                      <strong>
                        {isNaN(
                          (
                            parseFloat(amountToStake) -
                            parseFloat(stakeAfterFee)
                          )?.toFixed(5)
                        )
                          ? 0
                          : (
                              parseFloat(amountToStake) -
                              parseFloat(stakeAfterFee)
                            )?.toFixed(5)}{" "}
                        BNB
                      </strong>
                    </h5>
                    <h4>
                      Total Stake: <strong>{stakeAfterFee}</strong>
                    </h4>
                  </>
                )}

                {fuelError && (
                  <h3 className="error">Amount less than minimum required.</h3>
                )}

                <ButtonContainer>
                  <Button
                    disabled={fuelError}
                    onClick={stakeBNB}
                    className="Stake"
                    variant="contained"
                  >
                    Deposit
                  </Button>
                </ButtonContainer>
              </FuelBox>
            </ClickAwayListener>
          </PopupContainer>
        )}

        {showWithdraw && (
          <PopupContainer>
            <FuelBox>
              <div className="logoContainer">
                <img src={bnbcoinicon} alt="bnb Coin" />
              </div>
              <h1>Wallet</h1>

              <p>Amount available to withdraw:</p>

              <InputBox className="centerText">
                {/*<h5>
                  Wallet Amount: <strong>{userDets.balanceFull}</strong>
        </h5>*/}
                <InputContainer>
                  <input
                    value={withdrawalAmount.toFixed(12)}
                    /*onChange={(e) => {
                      handleWithdrawalInput(e);
                    }}*/
                    type="number"
                    min="0.01"
                    max="500"
                    disabled
                  />
                  <div>
                    <img src={bnblogoWhite} alt="bnb logo" />
                    <h4>BNB</h4>
                  </div>
                </InputContainer>

                {!maxWithdrawalError && (
                  <>
                    <h5>
                      {withdrawalFeeInfo.fee}% withdrawal Fee:{" "}
                      <strong>{withdrawFee} BNB</strong>
                    </h5>
                    {parseFloat(whaleTax) > 0.0 ? (
                      <h5>
                        Whale Tax:{" "}
                        <strong className="red">{whaleTax} BNB</strong>
                      </h5>
                    ) : (
                      <></>
                    )}
                  </>
                )}

                {maxWithdrawalError && (
                  <h3>Not enough funds in your wallet.</h3>
                )}
                
              </InputBox>

              {!maxWithdrawalError && (
                <h4>
                  Total Withdrawal: <strong>{withdrawAfterFee}</strong>
                </h4>
              )}


              <ButtonContainer>
                <Button
                  onClick={toggleWithdrawVisibility}
                  className="cancel"
                  variant="contained"
                >
                  Cancel
                </Button>
                <Button
                  disabled={maxWithdrawalError || max24hourError}
                  onClick={WithdrawBNB}
                  className="green"
                  variant="contained"
                >
                  Withdraw
                </Button>
              </ButtonContainer>
              
            </FuelBox>
          </PopupContainer>
          
        )}

        {showAdminWithdraw && (
          <PopupContainer>
            <ClickAwayListener
              onClickAway={() => setAdminWithdrawVisibility(false)}
            >
              <FuelBox>
                <div className="logoContainer">
                  <img src={bnbcoinicon} alt="bnb Coin" />
                </div>
                <h1>Wallet</h1>

                <p>How much would you like to withdraw?</p>

                <InputBox className="centerText">
                  <h5>
                    Wallet Amount: <strong>{adminDets.balance}</strong>
                  </h5>
                  <InputContainer>
                    <input
                      //placeholder={adminDets.balance}
                      value={adminWithdrawalInput}
                      onChange={(e) => {
                        setAdminWithdrawalInput(e.target.value);
                      }}
                      type="number"
                      min="0.01"
                      max="500"
                      
                    />
                    <div>
                      <img src={bnblogoWhite} alt="bnb logo" />
                      <h4>BNB</h4>
                    </div>
                  </InputContainer>

                  {/* <h5>10% withdrawal Fee: <strong>{withdrawFee}</strong></h5>
                        {parseFloat(whaleTax) > 0.0 ?

                            <h5>
                                Whale Tax: <strong className='red'>{whaleTax}</strong>
                            </h5> : <></>} */}
                </InputBox>

                {/* <h4>Total Withdrawal: <strong>{withdrawAfterFee}</strong></h4> */}

                <ButtonContainer>
                  <Button
                    onClick={toggleAdminWithdrawVisibility}
                    className="cancel"
                    variant="contained"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={WithdrawAdminEarnings}
                    className="green"
                    variant="contained"
                  >
                    Withdraw
                  </Button>
                </ButtonContainer>
              </FuelBox>
            </ClickAwayListener>
          </PopupContainer>
        )}

        {admin && (
          <AdminContainer>
            <AdminItems onClick={toggleVariableVisibility}>
              <h3>Variables</h3>
            </AdminItems>
            <AdminItems onClick={toggleStatusVisibility}>
              <PauseIcon />
              <h3>Pause/Resume</h3>
            </AdminItems>
            <AdminItems onClick={toggleSuspendVisibility}>
              <BlockIcon />
              <h3>Suspension</h3>
            </AdminItems>
            <AdminItems onClick={toggleMiscellaneousVisibility}>
              <MiscellaneousServicesIcon />
              <h3>Miscellaneous</h3>
            </AdminItems>
          </AdminContainer>
        )}

        {showVariable && (
          <PopupContainer>
            <ClickAwayListener onClickAway={() => setVariableVisibility(false)}>
              <Popup className="variable">
                <VariableRow>
                  <h5>Deposit Fee</h5>
                  <EditSection>
                    <FeeItems>
                      <FeeItem>
                        <h4>Total Fee</h4>
                        <input
                          className={`${editDepositFee ? "active" : ""}`}
                          active={!editDepositFee}
                          readOnly={!editDepositFee}
                          value={depositFee}
                          onChange={(e) => {
                            setDepositFee(e.target.value);
                          }}
                          type="number"
                          placeholder={depositFeeInfo.fee}
                        />
                      </FeeItem>

                      <FeeItem>
                        <h4>Admin Share</h4>
                        <input
                          className={`${editDepositFee ? "active" : ""}`}
                          readOnly={!editDepositFee}
                          value={depositFeeAdminShare}
                          onChange={(e) => {
                            setDepositFeeAdminShare(e.target.value);
                          }}
                          type="number"
                          placeholder={depositFeeInfo.adminShare}
                        />
                      </FeeItem>

                      <FeeItem>
                        <h4>Liquidity</h4>
                        <input
                          className={`${editDepositFee ? "active" : ""}`}
                          readOnly={!editDepositFee}
                          value={depositFeeLiquidityShare}
                          onChange={(e) => {
                            setDepositFeeLiquidityShare(e.target.value);
                          }}
                          type="number"
                          placeholder={depositFeeInfo.liquidty}
                        />
                      </FeeItem>
                    </FeeItems>
                    {!editDepositFee && (
                      <Button
                        onClick={() => {
                          setEditDepositFee(true);
                        }}
                        variant="contained"
                        className="edit"
                      >
                        Edit
                      </Button>
                    )}
                    {editDepositFee && (
                      <Button
                        onClick={() => {
                          updateDepositFee();
                        }}
                        variant="contained"
                      >
                        Save
                      </Button>
                    )}
                  </EditSection>
                </VariableRow>

                <VariableRow>
                  <h5>Withdrawal Fee</h5>
                  <EditSection>
                    <FeeItems>
                      <FeeItem>
                        <h4>Total Fee</h4>
                        <input
                          className={`${editWithdrawalFee ? "active" : ""}`}
                          readOnly={!editWithdrawalFee}
                          value={withdrawalFee}
                          onChange={(e) => {
                            setWithdrawalWithdrawal(e.target.value);
                          }}
                          type="number"
                          placeholder={withdrawalFeeInfo.fee}
                        />
                      </FeeItem>

                      <FeeItem>
                        <h4>Admin Share</h4>
                        <input
                          className={`${editWithdrawalFee ? "active" : ""}`}
                          readOnly={!editWithdrawalFee}
                          value={withdrawalFeeAdminShare}
                          onChange={(e) => {
                            setWithdrawalFeeAdminShare(e.target.value);
                          }}
                          type="number"
                          placeholder={withdrawalFeeInfo.adminShare}
                        />
                      </FeeItem>

                      <FeeItem>
                        <h4>Liquidity</h4>
                        <input
                          className={`${editWithdrawalFee ? "active" : ""}`}
                          readOnly={!editWithdrawalFee}
                          value={withdrawalFeeLiquidityShare}
                          onChange={(e) => {
                            setWithdrawalFeeLiquidityShare(e.target.value);
                          }}
                          type="number"
                          placeholder={withdrawalFeeInfo.liquidty}
                        />
                      </FeeItem>

                      <FeeItem>
                        <h4>Direct Referee</h4>
                        <input
                          className={`${editWithdrawalFee ? "active" : ""}`}
                          readOnly={!editWithdrawalFee}
                          value={withdrawalFeeDS}
                          onChange={(e) => {
                            setWithdrawalFeeDS(e.target.value);
                          }}
                          type="number"
                          placeholder={withdrawalFeeInfo.directReferee}
                        />
                      </FeeItem>
                    </FeeItems>

                    {!editWithdrawalFee && (
                      <Button
                        onClick={() => {
                          setEditWithdrawalFee(true);
                        }}
                        variant="contained"
                        className="edit"
                      >
                        Edit
                      </Button>
                    )}
                    {editWithdrawalFee && (
                      <Button
                        onClick={() => {
                          updatewithdrawalFee();
                        }}
                        variant="contained"
                      >
                        Save
                      </Button>
                    )}
                  </EditSection>
                </VariableRow>

                <VariableRow>
                  <h5>Performance Fee</h5>

                  <EditSection>
                    <FeeItems>
                      <FeeItem>
                        <h4>Total Fee</h4>
                        <input
                          className={`${editPerformanceFee ? "active" : ""}`}
                          readOnly={!editPerformanceFee}
                          value={performanceFee}
                          onChange={(e) => {
                            setPerformancedrawal(e.target.value);
                          }}
                          type="number"
                          placeholder={performanceFeeInfo.fee}
                        />
                      </FeeItem>

                      <FeeItem>
                        <h4>Admin Share</h4>
                        <input
                          className={`${editPerformanceFee ? "active" : ""}`}
                          readOnly={!editPerformanceFee}
                          value={performanceFeeAdminShare}
                          onChange={(e) => {
                            setPerformanceFeeAdminShare(e.target.value);
                          }}
                          type="number"
                          placeholder={performanceFeeInfo.adminShare}
                        />
                      </FeeItem>

                      <FeeItem>
                        <h4>Liquidity</h4>
                        <input
                          className={`${editPerformanceFee ? "active" : ""}`}
                          readOnly={!editPerformanceFee}
                          value={performanceFeeLiquidityShare}
                          onChange={(e) => {
                            setPerformanceFeeLiquidityShare(e.target.value);
                          }}
                          type="number"
                          placeholder={performanceFeeInfo.liquidty}
                        />
                      </FeeItem>

                      <FeeItem>
                        <h4>Upline</h4>
                        <input
                          className={`${editPerformanceFee ? "active" : ""}`}
                          readOnly={!editPerformanceFee}
                          value={performanceFeeUpline}
                          onChange={(e) => {
                            setPerformanceFeeUpline(e.target.value);
                          }}
                          type="number"
                          placeholder={performanceFeeInfo.upline}
                        />
                      </FeeItem>
                    </FeeItems>

                    {!editPerformanceFee && (
                      <Button
                        onClick={() => {
                          setEditPerformanceFee(true);
                        }}
                        variant="contained"
                        className="edit"
                      >
                        Edit
                      </Button>
                    )}
                    {editPerformanceFee && (
                      <Button
                        onClick={() => {
                          updateperformanceFee();
                        }}
                        variant="contained"
                      >
                        Save
                      </Button>
                    )}
                  </EditSection>
                </VariableRow>

                <VariableRow>
                  <h5>Referral Commission Fee</h5>

                  <EditSection>
                    <FeeItems>
                      <FeeItem>
                        <h4>Total Fee</h4>
                        <input
                          className={`${editRFCFee ? "active" : ""}`}
                          readOnly={!editRFCFee}
                          value={rfCFee}
                          onChange={(e) => {
                            setRFCFee(e.target.value);
                          }}
                          type="number"
                          placeholder={RCFInfo.fee}
                        />
                      </FeeItem>

                      <FeeItem>
                        <h4>Admin Share</h4>
                        <input
                          className={`${editRFCFee ? "active" : ""}`}
                          readOnly={!editRFCFee}
                          value={rfCFeeAdminShare}
                          onChange={(e) => {
                            setRFCFeeAdminShare(e.target.value);
                          }}
                          type="number"
                          placeholder={RCFInfo.adminShare}
                        />
                      </FeeItem>

                      <FeeItem>
                        <h4>Liquidity</h4>
                        <input
                          className={`${editRFCFee ? "active" : ""}`}
                          readOnly={!editRFCFee}
                          value={rfCFeeLiquidityShare}
                          onChange={(e) => {
                            setRFCFeeLiquidityShare(e.target.value);
                          }}
                          type="number"
                          placeholder={RCFInfo.liquidty}
                        />
                      </FeeItem>
                    </FeeItems>

                    {!editRFCFee && (
                      <Button
                        onClick={() => {
                          setEditRFCFee(true);
                        }}
                        variant="contained"
                        className="edit"
                      >
                        Edit
                      </Button>
                    )}
                    {editRFCFee && (
                      <Button
                        onClick={() => {
                          updateReferralCommissionFee();
                        }}
                        variant="contained"
                      >
                        Save
                      </Button>
                    )}
                  </EditSection>
                </VariableRow>

                <VariableRow>
                  <h5>Earning Rates</h5>
                  <EditSection>
                    <FeeItems>
                      <FeeItem>
                        <h4>{`Stake <= 100 BNB`}</h4>
                        <input
                          className={`${editEarningRates ? "active" : ""}`}
                          readOnly={!editEarningRates}
                          value={earningRateA}
                          onChange={(e) => {
                            setEarningRateA(e.target.value);
                          }}
                          type="number"
                          placeholder={earningRatesInfo.A}
                        />
                      </FeeItem>
                      <FeeItem>
                        <h4>{`Stake <= 250 BNB`}</h4>
                        <input
                          className={`${editEarningRates ? "active" : ""}`}
                          readOnly={!editEarningRates}
                          value={earningRateB}
                          onChange={(e) => {
                            setEarningRateB(e.target.value);
                          }}
                          type="number"
                          placeholder={earningRatesInfo.B}
                        />
                      </FeeItem>

                      <FeeItem>
                        <h4>{`Stake <= 500 BNB`}</h4>
                        <input
                          className={`${editEarningRates ? "active" : ""}`}
                          readOnly={!editEarningRates}
                          value={earningRateC}
                          onChange={(e) => {
                            setEarningRateC(e.target.value);
                          }}
                          type="number"
                          placeholder={earningRatesInfo.C}
                        />
                      </FeeItem>
                    </FeeItems>

                    {!editEarningRates && (
                      <Button
                        onClick={() => {
                          setEditEarningRates(true);
                        }}
                        variant="contained"
                        className="edit"
                      >
                        Edit
                      </Button>
                    )}
                    {editEarningRates && (
                      <Button
                        onClick={() => {
                          updateEarningRates();
                        }}
                        variant="contained"
                      >
                        Save
                      </Button>
                    )}
                  </EditSection>
                </VariableRow>

                <VariableRow>
                  <h5>Minimum Deposit Amount</h5>
                  <EditSection>
                    <FeeItems>
                      <FeeItem>
                        <input
                          className={`${editMinStake ? "active" : ""}`}
                          readOnly={!editMinStake}
                          value={minStake}
                          onChange={(e) => {
                            setMinStake(e.target.value);
                          }}
                          type="number"
                          placeholder={minimumStakeInfo}
                        />
                      </FeeItem>
                    </FeeItems>
                    {!editMinStake && (
                      <Button
                        onClick={() => {
                          setEditMinStake(true);
                        }}
                        variant="contained"
                        className="edit"
                      >
                        Edit
                      </Button>
                    )}
                    {editMinStake && (
                      <Button
                        onClick={() => {
                          updateMinimumStake();
                        }}
                        variant="contained"
                      >
                        Save
                      </Button>
                    )}
                  </EditSection>
                </VariableRow>

                <VariableRow>
                  <h5>Maximum Deposit Amount</h5>
                  <EditSection>
                    <FeeItems>
                      <FeeItem>
                        <input
                          className={`${editMaxStake ? "active" : ""}`}
                          readOnly={!editMaxStake}
                          value={maxStake}
                          onChange={(e) => {
                            setMaxStake(e.target.value);
                          }}
                          type="number"
                          placeholder={maximumStakeInfo}
                        />
                      </FeeItem>
                    </FeeItems>

                    {!editMaxStake && (
                      <Button
                        onClick={() => {
                          setEditMaxStake(true);
                        }}
                        variant="contained"
                        className="edit"
                      >
                        Edit
                      </Button>
                    )}
                    {editMaxStake && (
                      <Button
                        onClick={() => {
                          updateMaximumStake();
                        }}
                        variant="contained"
                      >
                        Save
                      </Button>
                    )}
                  </EditSection>
                </VariableRow>

                <Button onClick={toggleVariableVisibility} variant="contained">
                  Close
                </Button>
              </Popup>
            </ClickAwayListener>
          </PopupContainer>
        )}

        {showStatus && (
          <PopupContainer>
            <ClickAwayListener onClickAway={() => setStatusVisibility(false)}>
              <Popup className="status">
                <StatusContainer>
                  <Button
                    className="pause"
                    onClick={pauseContract}
                    variant="contained"
                  >
                    Pause
                  </Button>
                  <Button
                    className="resume"
                    onClick={resumeContract}
                    variant="contained"
                  >
                    Resume
                  </Button>
                </StatusContainer>
                <Button onClick={toggleStatusVisibility}>Close</Button>
              </Popup>
            </ClickAwayListener>
          </PopupContainer>
        )}

        {showSuspend && (
          <PopupContainer>
            <ClickAwayListener onClickAway={() => setSuspendVisibility(false)}>
              <Popup>
                <SuspensionContainer>
                  <Container>
                    <input
                      value={actionUser}
                      onChange={(e) => {
                        setActionUser(e.target.value);
                      }}
                      type="text"
                      placeholder="user address"
                    />
                    <Button
                      onClick={suspend}
                      className="suspend"
                      variant="contained"
                    >
                      Suspend
                    </Button>
                  </Container>

                  <Container>
                    <input
                      value={actionUser}
                      onChange={(e) => {
                        setActionUser(e.target.value);
                      }}
                      type="text"
                      placeholder="user address"
                    />
                    <Button
                      onClick={reinstate}
                      className="reinstate"
                      variant="contained"
                    >
                      Re-instate
                    </Button>
                  </Container>
                </SuspensionContainer>

                <Button onClick={toggleSuspendVisibility} variant="contained">
                  Close
                </Button>
              </Popup>
            </ClickAwayListener>
          </PopupContainer>
        )}

        {showMiscellaneous && (
          <PopupContainer>
            <ClickAwayListener
              onClickAway={() => setMiscellaneousVisibility(false)}
            >
              <Popup>
                <SuspensionContainer>
                  <Container>
                    <input
                      value={actionUser}
                      onChange={(e) => {
                        setActionUser(e.target.value);
                      }}
                      type="text"
                      placeholder="new owner address"
                    />
                    <Button onClick={transferOwnership} variant="contained">
                      Transfer Ownership
                    </Button>
                  </Container>

                  <Container>
                    <input
                      value={amountToStake}
                      onChange={(e) => {
                        setAmountToStake(e.target.value);
                      }}
                      type="number"
                      placeholder="liquidity amount"
                    />
                    <Button
                      onClick={AddLiquidity}
                      className="reinstate"
                      variant="contained"
                    >
                      Add Liquidity
                    </Button>
                  </Container>

                  {/*<Button
                    onClick={renounceOwnerShip}
                    className="red"
                    variant="contained"
                  >
                    Renounce Ownership
                    </Button>*/}
                </SuspensionContainer>
                <Button
                  onClick={toggleMiscellaneousVisibility}
                  variant="contained"
                >
                  Close
                </Button>
              </Popup>
            </ClickAwayListener>
          </PopupContainer>
        )}

        {teamEarning && (
          <PopupContainer>
            <ClickAwayListener
              onClickAway={() => setShowTeamLevelEarnings(false)}
            >
              <Popup>
                <img src={teamearnings} alt="teamEarnings" />
                <h3>TEAM.</h3>

                <TeamInfo>
                  <TeamInfoItem>
                    <h5>
                      TOTAL <br /> MEMBERS
                    </h5>
                    <h5 className="green">{userDets.totalTeamSize}</h5>
                  </TeamInfoItem>

                  <TeamInfoItem>
                    <h5>
                      TOTAL <br /> VOLUME
                    </h5>
                    <h5 className="green">{(userDets.downline).toFixed(5)} BNB</h5>
                  </TeamInfoItem>
                </TeamInfo>

                <ProgressBarContainer className="Progress">
                  <SliderContainer>
                    <CompletedProgress
                      style={{
                        width: `${
                          (parseFloat(userDets.downline) / parseFloat(100)) *
                          100
                        }%`,
                      }}
                    ></CompletedProgress>
                    <TotalProgress></TotalProgress>
                    <p>
                      {parseFloat(userDets.downline)} BNB / 100 BNB
                    </p>
                  </SliderContainer>
                </ProgressBarContainer>

                <h5>
                  reach a min volume of 100 BNB from your team to unlock a
                  residual bonus
                </h5>

                <Button
                  onClick={toggleTeamEarningVisibility}
                  className="TeamEarningClose"
                  variant="contained"
                >
                  Close
                </Button>
              </Popup>
            </ClickAwayListener>
          </PopupContainer>
        )}

        {level1 && (
          <PopupContainer>
            <ClickAwayListener onClickAway={() => showlevel1(false)}>
              <Popup>
                <img src={teamearnings} alt="teamEarnings" />
                <h3>TEAM - Level 1</h3>

                <TeamInfo>
                  <TeamInfoItem>
                    <h5>
                      TOTAL <br /> MEMBERS
                    </h5>
                    <h5 className="green">{userDets.teamSize[0]}</h5>
                  </TeamInfoItem>

                  <TeamInfoItem>
                    <h5>
                      TOTAL <br /> VOLUME
                    </h5>
                    <h5 className="green">
                      {userDets.downlineStakeInfo[0]} BNB
                    </h5>
                  </TeamInfoItem>
                </TeamInfo>

                {userDets.maxReferralLevel >= 0 ? (
                  <h5 className="green">
                    You are earnings commissions from this level
                  </h5>
                ) : (
                  <h5>
                    You must have a minimum of{" "}
                    <strong className="red">0.01 BNB</strong> staked to unlock
                    commissions from this level
                  </h5>
                )}

                <Button
                  onClick={togglelevel1Visibility}
                  className="TeamEarningClose"
                  variant="contained"
                >
                  Close
                </Button>
              </Popup>
            </ClickAwayListener>
          </PopupContainer>
        )}

        {level2 && (
          <PopupContainer>
            <ClickAwayListener onClickAway={() => showlevel2(false)}>
              <Popup>
                <img src={teamearnings} alt="teamEarnings" />
                <h3>TEAM - Level 2</h3>

                <TeamInfo>
                  <TeamInfoItem>
                    <h5>
                      TOTAL <br /> MEMBERS
                    </h5>
                    <h5 className="green">{userDets.teamSize[1]}</h5>
                  </TeamInfoItem>

                  <TeamInfoItem>
                    <h5>
                      TOTAL <br /> VOLUME
                    </h5>
                    <h5 className="green">
                      {userDets.downlineStakeInfo[1]} BNB
                    </h5>
                  </TeamInfoItem>
                </TeamInfo>

                {userDets.maxReferralLevel >= 1 ? (
                  <h5 className="green">
                    You are earnings commissions from this level
                  </h5>
                ) : (
                  <h5>
                    You must have a minimum of{" "}
                    <strong className="red">0.1 BNB</strong> staked to unlock
                    commissions from this level
                  </h5>
                )}

                <Button
                  onClick={togglelevel2Visibility}
                  className="TeamEarningClose"
                  variant="contained"
                >
                  Close
                </Button>
              </Popup>
            </ClickAwayListener>
          </PopupContainer>
        )}

        {level3 && (
          <PopupContainer>
            <ClickAwayListener onClickAway={() => showlevel3(false)}>
              <Popup>
                <img src={teamearnings} alt="teamEarnings" />
                <h3>TEAM - Level 3</h3>

                <TeamInfo>
                  <TeamInfoItem>
                    <h5>
                      TOTAL <br /> MEMBERS
                    </h5>
                    <h5 className="green">{userDets.teamSize[2]}</h5>
                  </TeamInfoItem>

                  <TeamInfoItem>
                    <h5>
                      TOTAL <br /> VOLUME
                    </h5>
                    <h5 className="green">
                      {userDets.downlineStakeInfo[2]} BNB
                    </h5>
                  </TeamInfoItem>
                </TeamInfo>

                {userDets.maxReferralLevel >= 2 ? (
                  <h5 className="green">
                    You are earnings commissions from this level
                  </h5>
                ) : (
                  <h5>
                    You must have a minimum of{" "}
                    <strong className="red">1 BNB</strong> staked to unlock
                    commissions from this level
                  </h5>
                )}

                <Button
                  onClick={togglelevel3Visibility}
                  className="TeamEarningClose"
                  variant="contained"
                >
                  Close
                </Button>
              </Popup>
            </ClickAwayListener>
          </PopupContainer>
        )}

        {level4 && (
          <PopupContainer>
            <ClickAwayListener onClickAway={() => showlevel4(false)}>
              <Popup>
                <img src={teamearnings} alt="teamEarnings" />
                <h3>TEAM - Level 4</h3>
                <TeamInfo>
                  <TeamInfoItem>
                    <h5>
                      TOTAL <br /> MEMBERS
                    </h5>
                    <h5 className="green">{userDets.teamSize[3]}</h5>
                  </TeamInfoItem>

                  <TeamInfoItem>
                    <h5>
                      TOTAL <br /> VOLUME
                    </h5>
                    <h5 className="green">
                      {userDets.downlineStakeInfo[3]} BNB
                    </h5>
                  </TeamInfoItem>
                </TeamInfo>

                {userDets.maxReferralLevel >= 3 ? (
                  <h5 className="green">
                    You are earnings commissions from this level
                  </h5>
                ) : (
                  <h5>
                    You must have a minimum of{" "}
                    <strong className="red">5 BNB</strong> staked to unlock
                    commissions from this level
                  </h5>
                )}

                <Button
                  onClick={togglelevel4Visibility}
                  className="TeamEarningClose"
                  variant="contained"
                >
                  Close
                </Button>
              </Popup>
            </ClickAwayListener>
          </PopupContainer>
        )}

        {level5 && (
          <PopupContainer>
            <ClickAwayListener onClickAway={() => showlevel5(false)}>
              <Popup>
                <img src={teamearnings} alt="teamEarnings" />
                <h3>TEAM - Level 5</h3>
                <TeamInfo>
                  <TeamInfoItem>
                    <h5>
                      TOTAL <br /> MEMBERS
                    </h5>
                    <h5 className="green">{userDets.teamSize[4]}</h5>
                  </TeamInfoItem>

                  <TeamInfoItem>
                    <h5>
                      TOTAL <br /> VOLUME
                    </h5>
                    <h5 className="green">
                      {userDets.downlineStakeInfo[4]} BNB
                    </h5>
                  </TeamInfoItem>
                </TeamInfo>

                {userDets.maxReferralLevel >= 4 ? (
                  <h5 className="green">
                    You are earnings commissions from this level
                  </h5>
                ) : (
                  <h5>
                    You must have a minimum of{" "}
                    <strong className="red">10 BNB</strong> staked to unlock
                    commissions from this level
                  </h5>
                )}

                <Button
                  onClick={togglelevel5Visibility}
                  className="TeamEarningClose"
                  variant="contained"
                >
                  Close
                </Button>
              </Popup>
            </ClickAwayListener>
          </PopupContainer>
        )}

        {admin ? (
          <AdminEarningContainer>
            <AdminWallet>
              <h2>Wallet</h2>

              <h1>{parseFloat(adminDets.balance).toFixed(5)}</h1>

              <AdminWithdraw onClick={toggleAdminWithdrawVisibility}>
                <AccountBalanceWalletIcon />
                <h3>Withdraw</h3>
              </AdminWithdraw>
            </AdminWallet>

            <AdminEarningItemContainer>
              <AdminEarningItem>
                <h1>Team Size</h1>
                <h3>{adminDets.teamSize}</h3>
              </AdminEarningItem>

              <AdminEarningItem>
                <h1>Downline Balance</h1>
                <h3>{adminDets.downline}</h3>
              </AdminEarningItem>

              <AdminEarningItem>
                <h1>Team Earnings</h1>
                <h3>{adminDets.teamEarning}</h3>
              </AdminEarningItem>

              <AdminEarningItem>
                <h1>Deposit Fees</h1>
                <h3>{adminDets.depositFee}</h3>
              </AdminEarningItem>

              <AdminEarningItem>
                <h1>Withdrawal Fees</h1>
                <h3>{adminDets.withdrawalFee}</h3>
              </AdminEarningItem>

              <AdminEarningItem>
                <h1>Referral Fees</h1>
                <h3>{adminDets.referralFee}</h3>
              </AdminEarningItem>

              <AdminEarningItem>
                <h1>Performance Fees</h1>
                <h3>{adminDets.performanceFee}</h3>
              </AdminEarningItem>

              <AdminEarningItem>
                <h1>Residual Comm.</h1>
                <h3>{adminDets.residualCommissions}</h3>
              </AdminEarningItem>
            </AdminEarningItemContainer>
          </AdminEarningContainer>
        ) : (
          <>
            <HeroText>
              <h1>GROWTH.</h1>
              <h1>ENGINE.</h1>
              <p>
              Leverage accelerated staking rewards in realtime, with our automated DeFi technology.
Generate passive BNB growth & instant withdrawals when using our app.
              </p>
            </HeroText>

            <EngineContainer
              active={account !== undefined && userDets.isRegistered}
              maxed={maxed}
            >
              <p maxed={maxed}>GROWTH CYCLE COMPLETE</p>

              <EngineHeader active={account !== undefined} maxed={maxed}>
                {maxed ? (
                  <></>
                ) : account === undefined ? (
                  <Button onClick={openModal} variant="contained">
                    <p>
                      Start <br />
                      Engine
                    </p>
                  </Button>
                ) : !userDets.isRegistered ? (
                  <Button variant="contained">
                    ENGINE
                    <br /> READY
                  </Button>
                ) : (
                  <>
                    <Button variant="contained">
                      <p>
                        Engine <br />
                        On
                      </p>
                    </Button>
                    <PulseRings className="Pulse">
                      <Pulse />
                      <Pulse />
                      <Pulse />
                    </PulseRings>
                  </>
                )}
              </EngineHeader>
              <EngineMain>
                <EngineMainItem active={account !== undefined} maxed={maxed}>
                  <Info
                    onClick={toggleShowFuelHistory}
                    active={account !== undefined}
                    maxed={maxed}
                  >
                    <h2>FUEL</h2>
                    <h1>{userDets.activeStake}</h1>
                    <h2>BNB</h2>
                  </Info>
                  <Button
                    onClick={toggleFuelVisibility}
                    className="AddFuel"
                    variant="contained"
                  >
                    ADD FUEL
                  </Button>
                </EngineMainItem>

                <EngineMainItem>
                  <EngineMeter>
                    <Meter
                      progress={userDets.progressin100}
                      earnings={userDets.currentEarning}
                    />
                  </EngineMeter>
                </EngineMainItem>

                <EngineMainItem
                  className="TEST"
                  active={account !== undefined && userDets.isRegistered}
                  maxed={maxed}
                >
                  <Info
                    onClick={toggleWithdrawHistoryVisibility}
                    active={account !== undefined && userDets.isRegistered}
                    maxed={maxed}
                  >
                    <h2>WALLET</h2>
                    <h1>{userDets.balance}</h1>
                    <h2>BNB</h2>
                  </Info>
                  <Button
                    disabled={parseFloat(userDets.balanceFull) <= 0}
                    onClick={toggleWithdrawVisibility}
                    className="withdraw"
                    variant="contained"
                  >
                    WITHDRAW
                  </Button>
                </EngineMainItem>
              </EngineMain>
              <EngineFooter>
                <EngineFooterItem
                  active={account !== undefined && userDets.isRegistered}
                  maxed={maxed}
                >
                  <h4>ACTIVE. </h4>
                  <h4>{userDets.timepassed} Days</h4>
                </EngineFooterItem>

                <EngineFooterItem
                  active={account !== undefined && userDets.isRegistered}
                  maxed={maxed}
                >
                  <h4>PROGRESS.</h4>
                  <h4>{userDets.progress} %</h4>
                </EngineFooterItem>

                <EngineFooterItem
                  active={account !== undefined && userDets.isRegistered}
                  maxed={maxed}
                >
                  <h4>YOUR STAKE.</h4>
                  <h4>{userDets.activeStake} BNB</h4>
                </EngineFooterItem>

                <EngineFooterItem
                  active={account !== undefined && userDets.isRegistered}
                  maxed={maxed}
                >
                  <h4>ROI.</h4>
                  <h4>{userDets.dailyEarningRate} %</h4>
                </EngineFooterItem>

                <EngineFooterItem
                  active={account !== undefined && userDets.isRegistered}
                  maxed={maxed}
                >
                  <h4>MAX</h4>
                  <h4>{userDets.maxEarning} BNB</h4>
                </EngineFooterItem>
              </EngineFooter>
            </EngineContainer>
            <Earnings>
              <EarningItem
                onClick={() => {
                  setShowPassiveEarnings(true);
                }}
                active={account !== undefined && userDets.isRegistered}
                maxed={maxed}
              >
                <h2>PASSIVE BNB.</h2>
                <h1>{userDets.passiveEarning}</h1>
                <h2>BNB</h2>
              </EarningItem>
              <EarningItem
                onClick={toggleGrowthVisibility}
                active={account !== undefined && userDets.isRegistered}
                maxed={maxed}
              >
                <h2>TOTAL GROWTH.</h2>
                <h1>{userDets.progress}%</h1>
                <h2>{userDets.currentEarning} BNB</h2>
              </EarningItem>

              <EarningItem
                onClick={toggleTeamLevelEarningVisibility}
                active={account !== undefined && userDets.isRegistered}
                maxed={maxed}
              >
                <h2>TEAM EARNINGS.</h2>
                <h1>{userDets.currentTeamEarning}</h1>
                <h2>BNB</h2>
              </EarningItem>

              <EarningItem
                active={account !== undefined && userDets.isRegistered}
                maxed={maxed}
              >
                <h2 className="team">TEAM.</h2>
                <h1 onClick={toggleTeamEarningVisibility}>
                  {userDets.totalTeamSize}
                </h1>
                <h2>Levels</h2>
                <div>
                  <h2
                    className={
                      userDets.isRegistered && userDets.teamSize[0] > 0
                        ? userDets.maxReferralLevel >= 0
                          ? "active"
                          : "inactive"
                        : ""
                    }
                    onClick={togglelevel1Visibility}
                  >
                    1
                  </h2>
                  <h2
                    className={
                      userDets.isRegistered && userDets.teamSize[1] > 0
                        ? userDets.maxReferralLevel >= 1
                          ? "active"
                          : "inactive"
                        : ""
                    }
                    id="2"
                    onClick={togglelevel2Visibility}
                  >
                    2
                  </h2>
                  <h2
                    className={
                      userDets.isRegistered && userDets.teamSize[2] > 0
                        ? userDets.maxReferralLevel >= 2
                          ? "active"
                          : "inactive"
                        : ""
                    }
                    id="3"
                    onClick={togglelevel3Visibility}
                  >
                    3
                  </h2>
                  <h2
                    className={
                      userDets.isRegistered && userDets.teamSize[3] > 0
                        ? userDets.maxReferralLevel >= 3
                          ? "active"
                          : "inactive"
                        : ""
                    }
                    id="4"
                    onClick={togglelevel4Visibility}
                  >
                    4
                  </h2>
                  <h2
                    className={
                      userDets.isRegistered && userDets.teamSize[4] > 0
                        ? userDets.maxReferralLevel >= 4
                          ? "active"
                          : "inactive"
                        : ""
                    }
                    id="5"
                    onClick={togglelevel5Visibility}
                  >
                    5
                  </h2>
                </div>
              </EarningItem>
            </Earnings>
          </>
        )}

        {admin && (
          <BamStatsContainer>
            <h1>B.A.M. STATS</h1>
            <table>
              <tbody>
                {/*
                <TableRow stats={true}>
                  <td>Total Members</td>
                  <td className="yellowText">{adminDets.member}</td>
        </TableRow>*/}

                <TableRow stats={true}>
                  <td>Total BNB Staked</td>
                  <td className="yellowText">{adminDets.bnbStaked} BNB</td>
                </TableRow>

                <TableRow stats={true}>
                  <td>Total Volume Locked</td>
                  <td className="yellowText">{adminDets.volumeLocked} BNB</td>
                </TableRow>

                <TableRow stats={true}>
                  <td>Reserve BNB</td>
                  <td className="yellowText">{adminDets.reserveBalance} BNB</td>
                </TableRow>

                <TableRow stats={true}>
                  <td>BNB Withdrawn</td>
                  <td className="yellowText">{adminDets.bnbWithdrawan} BNB</td>
                </TableRow>

                <TableRow stats={true}>
                  <td>Running Days</td>
                  <td className="yellowText">{adminDets.runningTime}</td>
                </TableRow>

                <TableRow stats={true}>
                  <td>Fees</td>
                  <td className="greenText">{adminDets.fees} BNB</td>
                </TableRow>
              </tbody>
            </table>
          </BamStatsContainer>
        )}
      </GrowthEngineContainer>
    </>
  );
}

export default GrowthEngine;

const PopupContainer = styled.div`
  z-index: 999;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.8);

  > .variable {
    /* background-color:pink; */
    min-width: 900px !important;
    padding: 20px 20px;
    padding-bottom: 10px;
    left:0;
    right:0;
    /* border-radius: 5% !important; */
    /* text-align:left; */

    @media(max-width: 767px){
      min-width: 100% !important;
      width: 70%;
      margin-left: auto;
      margin-right: auto;
      border-radius: 0px;
      left: 0;
      top: 0;
    }
  }

  > .status {
    min-width: 400px !important;
    max-width: 500px !important;
    min-height: 250px !important;
    max-height: 500px !important;
    @media(max-width: 767px){
      left: 0;
      right: 0;
      width: 80%;
      min-width: auto !important;
      max-width: 100% !important;
      
    }
  }

  > .fuelhistory {
    justify-content: flex-start;
    max-width: 700px;
  }

  > .referrerError {
    padding: 20px;
    text-align: center;
  }
`;

const FuelBox = styled.div`
  position: absolute;
  z-index: 999;
  left: 25%;
  right: 25%;
  top: 25%;
  bottom: 25%;
  min-width: 400px;
  max-width: 400px;
  min-height: 400px;
  max-height: 500px;
  border-radius: 10%;
  margin: auto;
  background: white;
  color: black;
  font-family: "Kdam Thmor Pro", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-top: 25px;
  padding-bottom: 25px;
  @media (max-width: 768px) {
    left: 0;
    right: 0;
    max-width: 340px;
    min-width: 340px;
  }

  > .logoContainer {
    /* border:1px solid pink; */
    padding: 5px;
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    > img {
      object-fit: contain;
      width: 100px;
    }

    .MuiSvgIcon-root {
      position: absolute;
      right: 0px;
      top: -5px;
      cursor: pointer;
    }
  }

  > .error {
    color: red;
  }
  > .fuelhistory {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  > h1 {
    font-size: 24px;
    font-weight: 500;
  }

  > h4 {
    background-color: #caf2db;
    color: black;
    font-weight: 300;
    > strong {
      color: #06bb3b;
    }
    padding-left: 20px;
    padding-right: 20px;
  }

  > h5 {
    color: gray;
    font-weight: 400;
  }

  > div {
    > h3 {
      color: red;
      font-size: 18px;
      font-weight: 500;
    }
  }

  > .centerText {
    text-align: center;
  }

  > .referrerErrorH1 {
    font-size: 28px;
  }

  > .referrerErrorP {
    color: gray;
    font-size: 18px;
  }

  > .disclaimer {
    color: gray;
    font-size: 14px;
  }
`;

const Popup = styled.div`
  position: absolute;
  z-index: 999;
  left: 25%;
  right: 25%;
  top: 5%;
  /* bottom: 15%; */
  max-width: 1000px;
  min-height: fit-content;
  /* max-height:vh; */
  border-radius: 4%;
  background-color: white;
  color: black;
  margin: auto;
  font-family: "Kdam Thmor Pro", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 25px;
  @media(max-width: 767px){
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
  }

  > h3 {
    font-size: 28px;
  }

  > .green {
    background-color: #e6f6ec;
    color: #3ab54b;
    padding-left: 20px;
    padding-right: 20px;
  }

  > p {
    margin: 10px;
    text-align: center;
  }

  > h4 {
    color: #cd0000;
    font-weight: 300;
  }

  > h5 {
    font-size: 21px;
    font-weight: 400;
    padding: 5px;
    > .red {
      color: red;
    }
  }
  > table {
    /* border:2px solid white; */
    width: 0%;
  }

  > .MuiButtonBase-root {
    margin-top: 10px;
    background-color: black;
    margin-bottom: 5px;
    color: white;
    :hover {
      color: white;
      background-color: #444547;
    }
  }

  > img {
    max-width: 120px;
    object-fit: contain;
  }

  > .TeamEarningClose {
    margin-bottom: 15px;
  }

  > .logoContainer {
    padding: 5px;
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    > img {
      object-fit: contain;
      width: 100px;
    }

    .MuiSvgIcon-root {
      position: absolute;
      right: 0px;
      top: -5px;
      cursor: pointer;
    }
  }
`;

const InputRow = styled.div`
  display: flex;
  > input {
    padding: 2px;
    margin: auto 2px;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  > .levelEarning {
    text-align: right;
    margin-right: 10px;
  }

  > h3 {
    font-weight: 400;
  }
`;

const StatusContainer = styled.div`
  width: 100%;
  min-height: 350px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;

  .pause {
    background-color: red;
    :hover {
      background-color: #cd0000;
    }
  }
  .resume {
    background-color: #39b54a;
    :hover {
      background-color: #058905;
    }
  }

`;

const SuspensionContainer = styled.div`
  width: 100%;
  min-height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  position: relative;

  > .red {
    background-color: red;
    :hover {
      background-color: #cd0000;
    }
  }

  > h3 {
    color: black;
    font-weight: 300;
  }
`;

const Container = styled.div`
  width: 90%;
  display: flex;
  justify-content: space-between;

  > input {
    width: 500px;
    padding: 3px;
    outline: none;
    @media(max-width: 767px){
      width: auto;
    }
  }

  > .MuiButtonBase-root {
    min-width: 200px;

  @media(max-width: 767px){
    min-width: auto;
  }
  }

  .suspend {
    background-color: red;
    :hover {
      background-color: #cd0000;
    }
  }
  .reinstate {
    background-color: #39b54a;
    :hover {
      background-color: #058905;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  @media(max-width: 767px){
    flex-direction: column
  }
`;

const InputBox = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  width: 90%;
  position: relative;
  > h5 {
    color: gray;
    font-weight: 400;

    > .red {
      color: red;
    }
  }
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: 2%;
  width: 100%;
  overflow: visible;
  > input {
    margin: 0;
    font-family: "Kdam Thmor Pro", sans-serif;
    font-size: 24px;
    padding-left: 5px;
    border: none;
    outline: none;
    background-color: whitesmoke;
    border-top-left-radius: 5%;
    border-bottom-left-radius: 5%;
    width: 80%;
  }

  > div {
    /* height:100%; */
    display: flex;
    align-items: center;
    padding-left: 3px;
    padding-right: 3px;
    border-top-right-radius: 10%;
    border-bottom-right-radius: 10%;
    background-color: gold;
    font-size: 24px;
    color: white;
  }

  > div > h4 {
    font-weight: 300;
  }

  > div > img {
    object-fit: contain;
    height: 1rem;
    margin-right: 5px;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const ButtonContainer = styled.div`
  min-width: 300px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 20px;
  margin-right: 20px;
  .cancel {
    min-width: 120px;
    background-color: gray;
    :hover {
      background-color: black;
    }
  }

  > .green {
    background-color: #39b54a;
    :hover {
      background-color: #058905;
    }
  }

  .Stake {
    width: 90%;
    background-color: black;

    :hover {
      background-color: gray;
    }
  }
`;

const GrowthEngineContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
`;

const HeroText = styled.div`
  color: white;
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
  > h1 {
    font-weight: 800;
    font-size: 50px;
  }
  > p {
    margin-top: 10px;
    text-align: center;
    min-width: 450px;
    overflow-x: visible !important;
    word-wrap: break-word;
    @media (max-width: 768px) {
      min-width: auto;
    }
  }
  @media (max-width: 768px) {
    width: 80%;
  }
`;

const AdminContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 50px auto;
`;

const AdminItems = styled.div`
  display: flex;
  border: 3px solid white;
  align-items: center;
  justify-content: center;
  margin: 10px 25px;
  min-width: 15rem;
  padding: 5px;
  cursor: pointer;

  > h3 {
    margin-left: 5px;
  }

  :hover {
    background-color: #8f968f;
  }
`;

const EngineContainer = styled.div`
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border: 3px solid red;

  width: 75%; /* TODO: Remove this */
  min-height: 75vh; /* TODO: Remove this */
  margin: 50px auto;
  border-radius: 45vh;
  outline: 5px solid white;
  outline-offset: 15px;
  @media(max-width: 400px){
 outline:0px;
  }

  ${({ active }) =>
    active &&
    `
    border:3px solid #ffd005;

  `}

  ${({ maxed }) =>
    maxed &&
    `
        border:3px solid green !important;
    `}
    
    >p {
    background-color: red;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 20px;
    text-align: center;
    display: none;
    ${({ maxed }) =>
      maxed &&
      `
       display:inline;
    `}
  }
`;

const EngineHeader = styled.div`
  color: white;
  padding: 20px;
  margin: 20px;
  position: relative;
  overflow: visible;
  @media(max-width: 400px){
    margin:0px;
    margin-bottom:10px;
    padding:0px;
  }
  > .MuiButtonBase-root {
    padding: 15px;
    border-radius: 100%;
    font-weight: 900;
    background-color: #008000;
    box-shadow: 0px 0px 8px #888888;
    outline: 10px solid #3fc83f;
    > p {
      font-weight: 500;
    }
    @media(max-width: 400px){
        outline: 0px;
    }
  }

  ${({ active }) =>
    active &&
    `
     >.MuiButtonBase-root{
        outline:1px solid #047904  !important;
        @media(max-width: 400px){
            outline: 0px !important;
        }
     }

  `}

  ::-webkit-scrollbar {
    display: none;
  }

  > .MuiButtonBase-root:hover {
    background-color: #39b54a;
  }
`;

const Pulse = styled.div`
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  /* background-color: pink; */
  border: 1px solid #39b54a;
  top: 0;
  left: 0;
  border-radius: 100%;
  transform: scale(0, 0);

  animation: pulse-custom 3000ms ease-out infinite;

  :nth-child(1) {
    animation-delay: 500ms !important;
  }
  :nth-child(2) {
    animation-delay: 1000ms !important;
  }
  :nth-child(3) {
    animation-delay: 1500ms !important;
  }
`;

const PulseRings = styled.div`
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -10;
  background-color: transparent;
  top: 0;
  left: 0;
  border-radius: 100%;
  overflow: visible;
  ::-webkit-scrollbar {
    display: none;

    > .ring1 {
    }

    > .ring2 {
      animation-delay: 1000ms !important;
    }
    > .ring3 {
      animation-delay: 1500ms !important;
    }
  }
`;

const EngineMain = styled.div`
  color: white;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: space-between;
  width: 100%;

  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
  }
`;

const EngineMainItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 33%;
  > Button {
    margin: 20px auto;
    width: 40%;
  }

  > .AddFuel {
    background-color: gray;
    min-width: fit-content;
  }

  > .withdraw {
    background-color: gray;
    min-width: fit-content !important;
  }

  min-width: fit-content;
  * {
    min-width: fit-content;
  }

  > .Mui-disabled {
    background-color: gray !important;
    color: white !important;
  }

  ${({ active }) =>
    active &&
    `
    >.withdraw{
        background-color: #39b54a ;
    }

    >.withdraw:hover{
        background-color: #058905;
    }
    

    >.AddFuel{
        background-color: red;
    }

    >.AddFuel:hover{
        background-color: #cd0000;
    }

  `}
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: gray;
  cursor: pointer;
  /* margin:30px auto; */
  > h1 {
    font-size: 4rem;
    font-family: "Kdam Thmor Pro", sans-serif;
  }

  ${({ active }) =>
    active &&
    `
    >h1,h2{
        color:white;
    }

    > h2:first-of-type {
        color: #ffd005;
    }

  `}

  ${({ maxed }) =>
    maxed &&
    `
    >h1,h2{
        color:white;
    }

    > h2:first-of-type {
        color: green !important;
    }

  `}
`;

const EngineMeter = styled.div`
  width: 99%;
  height: 100%;
  border-left: 5px solid white;
  border-right: 5px solid white;
  margin-left: 20px;
  margin-right: 20px;

  @media (max-width: 1000px) {
    border: none;
    margin-top: 40px;
    margin-bottom: 40px;
  }
`;

const EngineFooter = styled.div`
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 20px;
`;

const EngineFooterItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content:center; */
  text-align: center;
  margin: 10px;
  color: gray;

  ${({ active }) =>
    active &&
    `
        >h4{color:white;}
        >h4:first-of-type{
            color: #ffd005;
        }
    `}

  ${({ maxed }) =>
    maxed &&
    `
        >h4{color:white;}
        >h4:first-of-type{
        color: green !important;
        }   
  `}
`;

const Earnings = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const EarningItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  width: 180px;
  height: 180px;
  min-height: 180px;
  border-radius: 100%;
  padding: 25px;
  border: 2px solid gray;
  color: gray;
  margin: 25px;
  cursor: pointer;

  > .team {
    padding-top: 14%;
  }
  > h2 {
    font-family: "Kdam Thmor Pro", sans-serif;
    font-size: 17px;
  }

  > h1 {
    font-family: "Kdam Thmor Pro", sans-serif;
    font-size: 42px;
  }

  > div {
    display: flex;
    color: black;
    cursor: pointer;
    /* padding-top: 1px !important; */
    > h2 {
      font-size: 12px;
      margin: 2px;
      cursor: pointer;
      display: flex;
      vertical-align: middle;
      justify-content: center;
      padding: 5px;
      width: 20px;
      height: 20px;
      background-color: gray;
      border-radius: 100%;
      font-size: 12px;
      padding: 1px;
      font-family: "Kdam Thmor Pro", sans-serif;
    }
    > .active {
      background-color: white;
      border: 2px solid white;
    }
    > .inactive {
      background-color: white;
      border: 2px solid red;
    }
  }

  ${({ active }) =>
    active &&
    `
        color:white;
        border:2px solid #ffd005;

        >p{
            >h2{
            background-color:white;
            }
        }
  `}

  ${({ maxed }) =>
    maxed &&
    `
        color:white;
        border:2px solid green;

        >p{
            >h2{
            background-color:white;
            }
        }
  `}
`;

const BamStatsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  > h1 {
    font-size: 41px;
    margin: 20px auto;
  }

  > table {
    width: 100%;
    max-width: 400px;
  }

  @media(max-width: 500px){
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
`;

const TableRow = styled.tr`
  font-size: 22px;
  color: black;
  font-weight: 300;

  > td {
    margin: 5px;
    line-height: 2rem;
    vertical-align: bottom;
    > h5 {
      justify-self: center;
      font-weight: 400;
    }
  }

  > .yellowText {
    color: #ffd005;
  }

  > .greenText {
    color: #39b54a;
  }

  ${({ stats }) =>
    stats &&
    `
    color:white;
  `}
`;
const NextOwner = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const TeamInfo = styled.div`
  display: flex;
  width: 80%;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  > .passive {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 20px;
  }
  margin-top: 2 0px;
  margin-bottom: 20px; ;
`;

const TeamInfoItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px;

  border: 5px solid black;
  border-radius: 15%;

  color: gray;

  > h5 {
    font-size: 21px;
    font-weight: 500;
  }

  > .green {
    color: #39b54a;
    font-size: 24px;
  }

  > .iconContainer {
    display: flex;
    align-items: center;
    justify-content: center;

    > .MuiSvgIcon-root {
      height: 1rem;
    }
  }
`;

const ProgressBarContainer = styled.div`
  position: relative;
  margin: 30px auto;
  width: 80%;
  height: 25px;
  overflow: hidden;
  /* border: 1px solid pink; */
`;

const CompletedProgress = styled.div`
  display: inline-block;
  height: 25px;
  position: absolute;
  z-index: 100;
  background-color: #39b54a;
  border-radius: 23px;
  border: 1px solid #39b54a;
  top: 0;
`;

const TotalProgress = styled.div`
  display: inline-block;
  width: 98%;
  height: 25px;
  position: absolute;
  z-index: 99;
  background-color: white;
  border-radius: 25px;
  background-color: #e9ecef;
  text-align: right;
  padding-right: 10px;
`;
const SliderContainer = styled.div`
  * {
    margin: 0;
  }
  > p {
    position: absolute;
    right: 10px;
    z-index: 999;
    max-width: fit-content;
  }
`;

const AdminEarningContainer = styled.div`
  display: flex;
  width: 75%;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  /* border:5px solid pink; */
`;

const AdminWallet = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px;
  border-radius: 20px;
  background-color: #1a1a1a;
  border: 1px solid green;

  > h2 {
    font-weight: 300;
    margin: 5px;
  }

  > h1 {
    font-family: "Kdam Thmor Pro", sans-serif;
    font-size: 40px;
    margin: 10px;
  }
`;

const AdminWithdraw = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
  border: 3px solid white;
  padding: 3px 30px;
  cursor: pointer;
  :hover {
    background-color: black;
  }
`;

const AdminEarningItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  padding: 20px;
  border-radius: 20px;
  flex-wrap: wrap;
`;

const AdminEarningItem = styled.div`
  border: 1px solid white;
  word-wrap: break-word;
  padding: 40px;
  min-height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 15%;
  margin: 10px;
  border-radius: 20px;
  background-color: #1a1a1a;
  text-align: center;
  > h1 {
    font-size: 18px;
    font-weight: 300;
  }

  > h3 {
    font-family: "Kdam Thmor Pro", sans-serif;
    font-size: 40px;
  }

  @media(max-width:500px){
    width: 100%
  }
`;
const VariableRow = styled.div`
  width: 100%;
  > h5 {
    font-weight: 500;
    font-size: 18px;
  }
  @media(max-width: 767px){
    flex-direction: column;
  }
`;

const FeeItems = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding-bottom: 5px;
  border-bottom: 2px solid black;

  @media(max-width: 767px){
    flex-direction: column
  }
`;

const FeeItem = styled.div`
  > h4 {
    font-weight: 300;
  }
  margin: 10px;
  > input {
    padding: 2px;
  }
  > .active {
    color: #1976d2;
    border: 2px solid #1976d2;
    outline-color: #1976d2;
  }
`;

const EditSection = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  > Button {
    height: fit-content;
    padding-left: 40px;
    padding-right: 40px;
  }
  > .edit {
    background-color: black;
    :hover {
      background-color: gray;
    }
  }

  @media(max-width: 767px){
    flex-direction: column;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    > Button {
      margin-top: 20px
    }
  }


`;

const FuelHistory = styled.div`
  width: 90%;

  > table {
    width: 100%;

    > thead {
      width: 50%;
      text-align: left;
    }
    > tbody {
      /* width:50%; */
      /* border:1px solid pink; */
      max-height: 200px;
      overflow-y: scroll;
    }
  }
`;

const HistoryRow = styled.tr`
  > th {
    width: 50%;
    color: #5c5a5a;
    background-color: red;
    background-color: #f0eeee;
    font-weight: 400;
  }

  > .text-left {
    text-align: left;
  }

  > .withdraw {
    width: 30%;
  }
  > td {
    border: 1px solid black;
  }

  > .noBox {
    border: none;
  }

  > .transparent {
    background-color: transparent;
  }

  > .green {
    color: #3ab54b;
  }

  > .red {
    color: red;
  }
`;

const TeamEarning = styled.div`
  width: 50%;
  min-width: 250px;
  > table {
    /* width:100% !important; */
  }
`;

const Loading = styled.div`
  display: inline-block;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
`;
