import { useWeb3Modal } from '@web3modal/react'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { useAccount, useDisconnect } from 'wagmi'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { LogoutOutlined } from '@mui/icons-material';
import styled from "styled-components"
import Toast from "light-toast";
import { Web3Button } from "@web3modal/react";


export default function ConnectButton({isShown, setIsShown, redirect}) {
  const [loading, setLoading] = useState(false)
  const { open } = useWeb3Modal()
  const { isConnected, address, isConnecting } = useAccount()
  const location = useLocation();
  const { disconnect } = useDisconnect()
  const label =   isConnected ? `${address?.slice(0, 20)}...`: 'Connect' 

  const handleDisconnect = () => {
    if(isConnected){
     
        Toast.info('Disconnecting you...', 3000, () => {
            disconnect()
      window.location.reload()
      
    });
    }
  }

  useEffect(() => {
    if (isConnecting) {
        setLoading(true)
    }
    else {
        if (isConnected && loading) {
            setLoading(false)
            redirect()
        }
    }
    
  }, [isConnected, isConnecting])

  return (
<>

    {/* {address ?
                    (<>
                     <ButtonContainer disabled={loading}  onClick={() => setIsShown(!isShown)}>
                            <Wallet className='green ' active={address !== null}
                            >
                                <CheckCircleIcon sx={{
                                    color: '#39b54a'
                                }} />
                                <h3>  {loading ? 'Loading...' : label}</h3>
                            </Wallet>

                       
                    {isShown &&    <DisconnectButton onClick={() => handleDisconnect()}>  
<p> Disconnect</p>
    <LogoutOutlined sx={{
            color: '#f45252'
        }} />
 
    </DisconnectButton>}  </ButtonContainer>
    <MobileDisconnectButton onClick={() => handleDisconnect()}>  
<p> Disconnect</p>
    <LogoutOutlined sx={{
            color: '#f45252'
        }} />
 
    </MobileDisconnectButton>
                    </>
                       
                    ) :
                    (
                        <Wallet onClick={connectWallet}>
                            <AccountBalanceWalletIcon />
                            <h3>Connect Wallet</h3>
                        </Wallet>)} */}
                        <Web3Button  color="#eeeee" />
</>
   
  )
}

const Wallet = styled.div`
    display:flex;
    margin: auto 15px;
    cursor: pointer;

    position:relative;
    >h3{
        font-weight: 600;
        margin-left: 2px;
        font-size:16px;
    }
    align-items:center;
    justify-content:center;

    border:3px solid white;
    padding:10px 15px;

    
    :hover{
        background-color: white;
        color:black;
    }



    ${({ active }) => active && `
        border:3px solid #ffd005;

    `}
`;

const ButtonContainer = styled.div`
    position:relative;
    display:flex;

>.flex{
    display:flex;
}

>.none{
    display:none;
}
`;


const DisconnectButton = styled.button`
  cursor:pointer;
    position: absolute;
    top: -100;
    width: 220px;
    z-index: 99;
    bottom: -100;
    margin-top: 70px;
    right: 35px;
    left:0
    border-radius: 5px;
    border: none;
    border-radius: 5px;
    background: white;
    color: #f45252;
    display: flex;
    align-items:center;
    justify-content: space-between;
    padding: 8px;
    &:hover{
        background: #dbdbdb
    }
 
    @media(max-width: 768px){
        display: none;
    } 
`

const MobileDisconnectButton = styled(DisconnectButton)`
display: none;
@media(max-width: 768px){
    display: flex;
   
    position: inherit;
    margin-top: 0px;

}
@media(max-width: 766px){
    display: flex;
   
    position: inherit;
    margin-top: 20px;

}


`