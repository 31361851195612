import { Route, Routes } from 'react-router-dom';
import './App.css';
import GetStarted from './components/GetStarted';
import GrowthEngine from './components/GrowthEngine';
import Home from './components/Home';
import Share from './components/Share';
import { providerOptions } from "./components/assets"
// import Web3Modal from "web3modal";
import { ethers } from 'ethers';
import { useEffect, useState } from 'react';
import Footer from './components/Footer';
import styled from 'styled-components';


import { contractAddress, abi } from "./components/assets"
import FAQ from './components/FAQ';
import { useAccount, useDisconnect } from 'wagmi'
import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from "@web3modal/ethereum";

import { useWeb3Modal, Web3Modal } from "@web3modal/react";

import { configureChains, createConfig, WagmiConfig } from "wagmi";

import { bsc, polygon, avalanche, arbitrum, gnosis, optimism, fantom } from "wagmi/chains";
import { useProvider, useNetwork } from 'wagmi'
import { useNavigate } from "react-router-dom";




const chains = [bsc, polygon, avalanche, arbitrum, gnosis, optimism, fantom];

// Wagmi client
const { provider } = configureChains(chains, [
  w3mProvider({ projectId: "9c91629710bdc301aca577c7a2b373ae" }),
]);
const wagmiClient = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({
    projectId: "9c91629710bdc301aca577c7a2b373ae",
    version: "1", // or "2"
    appName: "web3Modal",
    disableInjectedProvider: false,
    chains,
  }),
  provider,
});



// Web3Modal Ethereum Client
const ethereumClient = new EthereumClient(wagmiClient, chains);


function Main() {
  const navigate = useNavigate();
  const { open } = useWeb3Modal()

  /*useEffect(() => {
    const threeScript = document.createElement("script");
    threeScript.setAttribute("id", "threeScript");
    threeScript.setAttribute("src", "https://cdnjs.cloudflare.com/ajax/libs/three.js/r134/three.min.js");
    document.getElementsByTagName("head")[0].appendChild(threeScript);
    return () => {
      if (threeScript)
        threeScript.remove();
    }
  }, []);*/

  const [provider, setProvider] = useState();
  const [library, setLibrary] = useState();
  const [account, setAccount] = useState();
  const [message, setMessage] = useState();
  const [snackBarOpen, setSnackBar] = useState(false);
  const [snackBarStyle, setSnackBarStyle] = useState("snackBarGreen");
  const [contractBalance, setContractBalance] = useState(0);
  const { address, isConnected } = useAccount()
  const { chain } = useNetwork()
  


  const switchNetwork = async () => {
  
    if(window.ethereum){
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: '0x38' }],
      });
    } catch (switchError) {
      console.log(switchError, "SWITCH ERROR")
      // This error code indicates that the chain has not been added to MetaMask.
      if (switchError.code === 4902) {
        try {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: '0x38',
                chainName: "BNB Smart Chain",
                rpcTarget: ["https://bsc-dataseed.binance.org"],
                blockExplorer: ["https://bscscan.com"],
                displayName: "Binance Smart Chain",
                ticker: "BNB",
                tickerName: "BNB",
              },
            ],
          });
        } catch (addError) {
          console.log("ERROR MESSAGE")
          throw addError;
        }
      }
    }
  }
  }

  const disconnect = async () => {
    // await web3Modal.clearCachedProvider();
   

    // refreshState();
  };


  
  const connectWallet = async (address) => {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum)
      const library = new ethers.providers.Web3Provider(provider);
     setAccount(address)

      if (chain?.id !== 56) {
        await switchNetwork();
      }
      setProvider(provider);
      setLibrary(library);

      /*setMessage(`Wallet Connected!`);
      setSnackBarStyle("snackBarGreen")*/

      const contract = new ethers.Contract(contractAddress, abi, library);
     
      const balance = (await provider.getBalance(contractAddress)).toString();
      console.log(balance)

      setContractBalance(parseFloat(ethers.utils.formatEther((balance).toString())).toFixed(12))
      
      
    } catch (error) {
      console.log(error)
    }
  };

const openWeb3Modal = async () => {
  await open()
}


  useEffect(() => {
    if(isConnected && address){
      connectWallet(address)
    } 

  },[address])

  return (
    <>
      <Routes>
        

        <Route path="/" element={<Home
          connectWallet={connectWallet} disconnect={disconnect} account={account} active={account !== undefined}
        />} />
        <Route path="/:referee" element={<Home
          connectWallet={connectWallet} disconnect={disconnect} account={account} active={account !== undefined}
        />} />
        <Route path="/GrowthEngine" element={
          <GrowthEngine
            disconnect={disconnect}
            provider={provider?.provider}
            library={library?.provider}
            account={account}
            setAccount={setAccount}
            message={message}
            setMessage={setMessage}
            snackBarOpen={snackBarOpen}
            snackBarStyle={snackBarStyle}
            setSnackBar={setSnackBar}
            setSnackBarStyle={setSnackBarStyle}
            connectWallet={connectWallet}
            contractBalance={contractBalance}
          />} />
        <Route path="/GrowthEngine/:referee" element={
          <GrowthEngine
            disconnect={disconnect}
            switchNetwork={switchNetwork}
            provider={provider?.provider}
            setProvider={setProvider}
            library={library?.provider}
            setLibrary={setLibrary}
            account={account}
            setAccount={setAccount}
            message={message}
            setMessage={setMessage}
            snackBarOpen={snackBarOpen}
            snackBarStyle={snackBarStyle}
            setSnackBar={setSnackBar}
            setSnackBarStyle={setSnackBarStyle}
            connectWallet={connectWallet}
            contractBalance={contractBalance}
          />} />
        <Route path="/Share/" element={
          <Share
            library={library?.provider}
            connectWallet={connectWallet} disconnect={disconnect} account={account} active={account !== undefined}
          />}
        />
        <Route path="/Share/:referee" element={
          <Share
            library={library?.provider}
            connectWallet={connectWallet} disconnect={disconnect} account={account} active={account !== undefined}
          />}
        />
        <Route path="/GetStarted/" element={<GetStarted
          connectWallet={connectWallet} disconnect={disconnect} account={account} active={account !== undefined}
        />} />
        <Route path="/GetStarted/:referee" element={<GetStarted
          connectWallet={connectWallet} disconnect={disconnect} account={account} active={account !== undefined}
        />} />

        <Route path="/FAQ/" element={<FAQ
          connectWallet={connectWallet} disconnect={disconnect} account={account} active={account !== undefined}
        />} />
        <Route path="/FAQ/:referee" element={<FAQ
          connectWallet={connectWallet} disconnect={disconnect} account={account} active={account !== undefined}
        />} />

      </Routes>

      <Footer />
      </>
  );
}

export default Main;

const AppC = styled.div`

`;