import React, { useState } from 'react'
import styled from 'styled-components'
// import { motion } from 'framer-motion'


function FAQItems({ question, answer }) {

    const [show, setShow] = useState(false)
    const toggleShow = () => {
        setShow(!show);
    }

    return (
        <ItemContainer>
            <Question onClick={toggleShow}>
                {show ? <span className='symbol hidden'>-</span> : <span className='symbol visible'>+</span>}
                <h3>{question}</h3>

            </Question>
            <Answer>
                <p className={show ? "visible" : "hidden"}>{answer}</p>
            </Answer>
        </ItemContainer>
    )
}

export default FAQItems

const ItemContainer = styled.div`
    margin-top:20px;
    margin-bottom: 10px;
    width:85%;
`

const Question = styled.div`
    cursor: pointer;
    display:flex;
    align-items:center;
    margin-bottom: 10px;
    >.symbol{
        font-weight: 400;
        font-size:1.5rem;
        margin-right:5px;
    }

    >.hidden{
        color:lightgreen;
    }

    >h3{
        font-weight:300;
        font-size: 1.5rem;
    }
`;

const Answer = styled.div`
    >p{
        transition:all .4s  linear;
        overflow: hidden;
        font-weight: 200;
    }
    
    >.visible{
        max-height: 400px;
    }
    >.hidden{
        max-height: 0;
    }

`;