import { Button } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { backgroundImage, cogwheel, teamMembers, digitalWallet, settings, solarEnergy, trendingUp, wallet, tractorIcon, step3, injectionIcon, defiIcon } from './assets'
import Navbar from './Navbar'
import Lottie from "lottie-react";
import { useWeb3Modal } from '@web3modal/react'
import { useAccount, useDisconnect } from 'wagmi'

import "animate.css/animate.min.css";
import { AnimationOnScroll } from 'react-animation-on-scroll';

import BIRDS from "vanta/dist/vanta.net.min"
import * as THREE from "three"

import ReactPlayer from 'react-player'
import { useNavigate, useParams } from 'react-router-dom'




function Home({ disconnect, connectWallet, active, account }) {
const navigate = useNavigate()
    const { open } = useWeb3Modal()
    const [opened, setOpened] = useState(false)
    const { isConnected } = useAccount()

    const { referee } = useParams();

async function onOpen() {
  await open()
}

function openModal() {
    if(!account){
        onOpen()
        setOpened(true)
    } else {
        console.log(referee)
        if (referee) {
            navigate("/GrowthEngine/" + referee)  
        }
        else {
            navigate("/GrowthEngine")  
        }
        
    }
}

useEffect(() => {
    if(opened && isConnected){
        setTimeout(() => {
            console.log(referee)
            if (referee) {
                navigate("/GrowthEngine/" + referee)  
            }
            else {
                navigate("/GrowthEngine")  
            }
        }, 1000);
     
    }

}, [open, isConnected])


    const [vantaEffect, setVantaEffect] = useState(0)
    const myRef = useRef(null)
    useEffect(() => {
        if (!vantaEffect) {
            setVantaEffect(BIRDS({
                el: myRef.current,
                THREE
            }))
        }
        return () => {
            if (vantaEffect) vantaEffect.destroy()
        }
    }, [vantaEffect])

    return (
        <HomeContainer>
            <Section1>
                <Navbar connectWallet={connectWallet} disconnect={disconnect} account={account} active={account !== undefined} />

                <MainBox>
                    <MainPartition>

                        <h1>
                            BNB.<br />
                            ACCELERATION.<br />
                            MODEL.<br />
                        </h1>
                        <p>
                            Leverage accelerated yield farming rewards with our automated, decentralized technology. Generate up to 2.5% gains passively everyday, by depositing BNB in our app.

                        </p>
                        <Button onClick={openModal} variant='contained'>Get Started</Button>

                    </MainPartition>
                    <MainPartition className='player'>
                        <ReactPlayer playing={true} volume={1} muted={true} controls={true} url='https://youtu.be/QN6mSTcRrTQ' />

                        {/* <video width="100%" height="100%" loop autoPlay muted>
                            <source src={homeVideo} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video> */}
                    </MainPartition>
                </MainBox>


            </Section1>
            <Section className='section1'>
                <Box>
                    <img className="animate" src={cogwheel} alt="cogWheel" />
                    <h1>
                        AUTONOMOUS.<br />
                        GROWTH.<br />
                        ENGINE.<br />
                    </h1>
                    <p>Our automated system was built with speed, growth & stability to take you from zero to hero. As a member of BAM you have an opportunity to become a Liquidity Provider and generate perpetual crypto rewards.</p>
                    <p><i>Experience accelerated asset growth with <strong>2.5<span>X</span> leverage</strong> and % gains you can experience in realtime.</i></p>
                    <Button onClick={openModal} variant='contained'>Get Started</Button>
                </Box>
            </Section>
            <Section className='section2'>
                <Box className='box2'>
                    <h1>
                        DISRUPTIVE.<br />
                        FINANCIAL.<br />
                        ECOSYSTEM.
                    </h1>
                    <InfoContainer>
                        <AnimationOnScroll animateIn="animate__slideInLeft">
                            <Info>

                                <Lottie animationData={settings} style={{ width: "60px", height: "90px", overflow: "hidden", marginLeft: "0px" }} />

                                <h1>Automated.</h1>
                                <p>
                                    Simply stake & earn passive yields on your crypto assets. With BAM you can experience a true plug & play system built for one specific task; Growth. With our multi-faceted liquidity pool and our sophisticated algorithms working away while you sleep, we aim to deliver a flawless experience.
                                </p>

                            </Info>
                        </AnimationOnScroll>

                        <AnimationOnScroll animateIn="animate__slideInUp">
                            <Info>
                                <Lottie animationData={trendingUp} style={{ width: "60px", height: "90px", overflow: "hidden", marginLeft: "0px" }} />
                                <h1>Realtime Growth.</h1>
                                <p>
                                    Watch the growth of your assets in realtime, up to 2.5% every day. BAM is
                                    the go-to tool for passive investors who want real time freedom and maximum
                                    % gains. Grow your portfolio and receive farming rewards up to 250% on your crypto
                                    . Repeat as often as you want, with the option to re-stake and accelerate your growth.</p>
                            </Info>
                        </AnimationOnScroll>

                        <AnimationOnScroll animateIn="animate__slideInRight">
                            <Info>
                                <Lottie animationData={solarEnergy} style={{ width: "60px", height: "90px", overflow: "hidden", marginLeft: "0px" }} />
                                <h1>Sustainable.</h1>
                                <p>
                                    BAM takes the game to another level with our LIT technology. This unique perpetual liquidity injection feature works for you. As the heartbeat of your financial ecosystem, it was created to be a self-sufficient cog in the mechanics. It will feed in any surplus liquidity into the protocol, as your activity increases. The result; increased stability, even when the markets are bleeding..
                                </p>
                            </Info>
                        </AnimationOnScroll>


                    </InfoContainer>


                    <InfoContainer>
                        <AnimationOnScroll animateIn="animate__slideInLeft">
                            <Info>
                                <Lottie animationData={teamMembers} style={{ width: "60px", height: "90px", overflow: "hidden", marginLeft: "0px" }} />
                                <h1>Decentralized Freedom.</h1>
                                <p>
                                    BAM is a verified DeFi smartcontract protocol on the BSC network. That’s why our smartcontract is embedded, publicly viewable, verified and cannot be altered. Join with confidence using our fast & secure DeFi protocol.
                                </p>
                            </Info>
                        </AnimationOnScroll>

                        <AnimationOnScroll animateIn="animate__slideInUp">
                            <Info>
                                <Lottie animationData={wallet} style={{ width: "60px", height: "90px", overflow: "hidden", marginLeft: "0px" }} />
                                <h1>Instant Withdrawals..</h1>
                                <p>
                                    Why should you have to wait for your crypto? Receive any % gains generated, instantly to your connected wallet. DeFi makes this possible and with 1-click you’re in the money. No surprises, no headaches. Just hassle-free crypto growth.
                                </p>
                            </Info>
                        </AnimationOnScroll>

                        <AnimationOnScroll animateIn="animate__slideInRight">
                            <Info>
                                <Lottie animationData={digitalWallet} style={{ width: "60px", height: "90px", overflow: "hidden", marginLeft: "0px" }} />
                                <h1>Passive Income.</h1>
                                <p>
                                    Securely stake, earn, withdraw & track the progress of your asset growth. BAM is a pioneer in the space of reliable passive income on the BSC network. Stake from 0.01 BNB and achieve stable, passive yields, accessible to withdraw anytime.
                                </p>
                            </Info>
                        </AnimationOnScroll>


                    </InfoContainer>

                    <Button onClick={openModal} variant='contained'>Get Started</Button>

                </Box>

            </Section>

            <Section className='section3'>
                <Box1 className='box3'>
                    <h1>
                        ELASTIC.<br />
                        FARMING.<br />
                        YIELDS.
                    </h1>

                    <PartitionContainer>
                        <Partition>
                            <h1>
                                Earn higher % yields on your journey as you grow.
                            </h1>
                            <p>
                                Automated farming rewards you up to 2.5% everyday in real-time.
                            </p>
                            <img src={tractorIcon} alt="tractorIcon" />
                        </Partition>
                        <Partition>
                            <h1>
                                We keep things simple. Stake more to earn more.
                            </h1>
                            <p>
                                Unlock high-tier rewards repeatedly with 2.5X leverage in BNB
                            </p>
                            <img src={step3} alt="BNB" />
                        </Partition>
                    </PartitionContainer>

                    <Button onClick={openModal} variant='contained'>Get Started</Button>

                </Box1>
            </Section>

            <Section className='section1'>
                <Box>
                    <h1>
                        PERPETUAL.<br />
                        LIQUIDITY.<br />
                        INJECTION.<br />

                    </h1>
                    <img src={injectionIcon} alt="injectionIcon" />

                    <p>BAM was created with speed, growth, sustainability & a self-perpetuating “feed in” system. This keeps the heartbeat of the protocol beating strong and will actually accelerate with increased user-activity. Surplus liquidity is pumped into the contract from fees, farms, pools, and other protocols that feed into the BAM financial ecosystem.</p>
                    <Button onClick={openModal} variant='contained'>Get Started</Button>
                </Box>

                {/* <div class="wistia_responsive_padding" style={{ padding: "56.25% 0 0 0", position: "relative" }}>
                    <div class="wistia_responsive_wrapper" style={{ height: "100%", left: "0", position: "absolute", top: "0", width: "100%" }}>
                        <div class="wistia_embed wistia_async_icn3wq3nph videoFoam=true" style={{ height: "100%", position: "relative", width: "100%" }}>
                            <div class="wistia_swatch" style={{ height: "100%", left: "0", opacity: "0", overflow: "hidden", position: "absolute", top: "0", transition: "opacity 200ms", width: "100%" }}>
                                <img src="https://fast.wistia.com/embed/medias/icn3wq3nph/swatch" style={{ filter: "blur(5px)", height: "100%", objectFit: "contain", width: "100%" }} alt="TEmp" aria-hidden="true" onload="this.parentNode.style.opacity=1;" />
                            </div>
                        </div>
                    </div>
                </div> */}
            </Section>

            <Section ref={myRef} id="backgroundhere">
                <Box className='box4'>
                    <h1>
                        DEFI.<br />
                        FABRIC.<br />
                        FRAMEWORK.<br />
                    </h1>
                    <img src={defiIcon} alt="defiIcon" />
                    <p>
                        With BAM, you can be your own bank. We define DeFi as decentralized freedom. Placing ownership, power & control of your financials back into your hands. That means no middlemen, no intermediaries, increased gains, anonymity, diminished 3rd party intervention, speed & growth. Freedom using the power of DeFi.
                    </p>
                    <Button onClick={openModal} variant='contained'>Get Started</Button>

                </Box>
            </Section>
        </HomeContainer>

    )
}

export default Home

const HomeContainer = styled.div`

    >.section1{
        background-color:#f3f3f3;
    }

    >.section2{
        background-color: white;
    }

    >.section3{
        background-image: url(${backgroundImage});
        background-size: contain;
        background-position: -600px;
        background-repeat: no-repeat;
    }

    *{
        box-sizing: border-box;;
    }
`

const Section1 = styled.div`
    background-image: url(${backgroundImage});
    background-size: cover;
    background-position: right;
    background-repeat: no-repeat;
    display:inline-block;
    width:100vw;
    min-height:100vh;
    padding-bottom: 20px;;
`;

const Section = styled.div`
    width:100vw;
    min-height:100vh;
    display:flex;
    justify-content:center;

    >.box2{
        max-width: 100%;
        padding:60px;

        *{
            margin: auto;
        }
    }

    >.box3{
        @media(max-width: 768px){
            padding: 0px;
        }
    }

    >.box4{
        color:white;

        >Button{
        font-size:22px;
        font-weight:700;
        padding-left:30px;
        padding-right:30px;

        border: 5px solid white;
        background-color: black;
        :hover{
            background-color:#43464d;
        }

    }
    }
`;

const Box = styled.div`
    display:flex;
    align-items:center;
    flex-direction: column;
    box-sizing: border-box;
    padding:40px;
    min-height:100%;
    max-width:50%;
    min-width: 500px !important;

    >.animate{
        animation-name: spin;
        animation-duration: 5000ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear; 
    }
    >img{
        object-fit: contain;
        width:140px;
       
    }
    >h1{
        text-align: center;
        font-size: 50px;
    }

    >p{
        text-align: center;
        font-size:20px;
        overflow:hidden;
        >i>strong>span{
            color:red;
        }

        @media(max-width: 640px){
            margin: 28px 35px;
        }
    }

    >Button{
        font-size:22px;
        font-weight:700;
        padding-left:30px;
        padding-right:30px;
        background-color: black;
        :hover{
            background-color:#43464d;
        }

    }

    *{
        margin: 25px auto;;
    }
`;

const Box1 = styled.div`
    display:flex;
    align-items:center;
    flex-direction: column;
    box-sizing: border-box;
    padding:40px;
    min-height:100%;

    box-sizing: border-box;
    color:white;
    width:100% !important;
    >h1{
        text-align: center;
        font-size: 50px;
    }

    >Button{
        font-size:22px;
        font-weight:700;
        padding-left:30px;
        padding-right:30px;

        border: 5px solid white;
        background-color: black;
        :hover{
            background-color:#43464d;
        }

    }

    
    *{
        margin: 25px auto;;
    }
`;

const InfoContainer = styled.div`
    display:flex;
    flex-wrap: wrap;
    align-items:center;
    justify-content: center;

    ::-webkit-scrollbar {
    display: none;
}
`;

const Info = styled.div`
    margin:10px;
    max-width:330px;
    padding:20px;
    min-height:500px;

    >h1{
        font-size: 24px;
    }
    @media (max-width:1200px) {
        max-width:80%;
        min-height: 200px;
    }
  
`

const PartitionContainer = styled.div`
    display:flex;
    /* max-width:100%; */
    width:80%;
    display:flex;
    flex-wrap: wrap;
    justify-content:space-around
`;

const Partition = styled.div`
    background-color: white;
    color:black;
    display:flex;
    flex-direction: column;
    justify-content:center;
    align-items:center;
    max-width: 400px;
    padding:20px;
    padding-left:30px;
    padding-right:30px;
    >h1{
        font-size: 35px;
    }
    >p{
        font-size:21px;
        margin:40px auto;
    }

    >img{
        object-fit: contain;
        width:150px;
    }
`;

const MainBox = styled.div`
    /* border:5px solid pink; */
    width:90%;
    /* height:100%; */
    margin:auto;

    display:flex;
    flex-wrap: wrap;

    
    >.player{
        margin:auto;
        *{
            ::-webkit-scrollbar {
             display: none;
            }
        }
>div {
    @media(max-width: 768px){
        width: auto !important;
    }
   
}
    }
`;

const MainPartition = styled.div`
    color:white;
   
    height:100%;
    max-width: 50%;

    @media (max-width:1200px) {
        max-width:80%;
        margin: 20px;
    }

    /* border: 1px solid aqua; */
    >h1{
        font-size: 70px;
        margin-top: 40px;
        margin-bottom: 20px;;
    }

    >p{
        font-size: 20px;
        margin-top: 20px;
        margin-bottom: 20px;;
    }

    >Button{
        margin-top: 40px;
        font-size:22px;
        font-weight:700;
        padding-left:30px;
        padding-right:30px;

        border: 5px solid white;
        background-color: black;
        :hover{
            background-color:#43464d;
        }

    }
`;