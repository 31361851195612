import React, {useState} from 'react'
import styled from 'styled-components'
import { bamlogoweb, Whitepaper } from './assets';
import { NavLink, useParams, useNavigate, useLocation  } from 'react-router-dom';
import ConnectButton from './ConnectButton';


function Navbar() {
    const [isShown, setIsShown] = useState(false)

    const { referee } = useParams();
    const navigate = useNavigate()
    const location = useLocation();
    

    const redirect = () => {
        if (referee) {
            navigate("/GrowthEngine/" + referee)  
        }
        else {
            navigate("/GrowthEngine")  
        }
    }

    return (
        <NavBarContainer>
            <NavBarLeft>
                <img src={bamlogoweb} alt="bamLogo" />
            </NavBarLeft>

            <NavBarRight>
                <NavLinkC end to={referee === undefined ? "/" : `/${referee}`}>
                    <p>Home</p>
                </NavLinkC>

                <NavLinkC to={referee === undefined ? "/GrowthEngine/" : `/GrowthEngine/${referee}`} >
                    <p>Growth Engine</p>
                </NavLinkC>


                <a className='whitepaper' rel="noreferrer" href={Whitepaper} target="_blank" >
                    <p>Whitepaper</p>
                </a>

                <NavLinkC to={referee === undefined ? "/Share/" : `/Share/${referee}`} >
                    <p>Share</p>
                </NavLinkC>

                <NavLinkC to={referee === undefined ? "/GetStarted/" : `/GetStarted/${referee}`} >
                    <p>Get Started</p>
                </NavLinkC>

                <NavLinkC to={referee === undefined ? "/faq" : `/faq/${referee}`}>
                    <p>FAQ</p>
                </NavLinkC>

{/*Connect Wallet Modal from Web3Modal with custom button */}
<ConnectButton isShown={isShown} setIsShown={setIsShown} redirect={redirect}/>
            </NavBarRight>
        </NavBarContainer >
    )
}

export default Navbar;

const NavBarContainer = styled.div`
    color:white;
    display:flex;
    flex-wrap: wrap;
    width:100%;
    justify-content: space-between;

    align-items:flex-end;
    padding: 20px var(--padding_right);
    box-sizing: border-box;
    background-color: rgba(0,0,0,.8);
    @media (max-width: 1290px) {
        justify-content: center;
    }
    min-height:90px;
    @media(max-width: 768px){
        padding:0px;
    }
    
`;
const NavBarLeft = styled.div`
    >img{
        object-fit: contain;
        width:140px;
        margin:15px;
    }
`;

 

const NavBarRight = styled.div`
    display:flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content:center;
 
    >.green{
        border:3px solid #39b54a ;
        pointer-events: none;
    }
 
    >a{
        text-decoration: none !important;
        color:white;
        font-size: 18px;
        font-weight: 400;
        white-space:nowrap;
    }

    >.active{
        border-bottom:5px solid #ffd005 !important;
    }

    >.whitepaper{
        margin: auto 15px;
        cursor: pointer;
        box-sizing: border-box;
        transition: all .1s linear;
        border-bottom:5px solid transparent;
        >p{
            font-size:16px;
        }
        :hover{
            border-bottom:5px solid white;
        }
    }

`;



const NavLinkC = styled(NavLink)`

    margin: 15px;
    cursor: pointer;
    box-sizing: border-box;
    transition: all .1s linear;
    border-bottom:5px solid transparent;
    :hover{
        border-bottom:5px solid white;
    }

    >p{
        font-size: 16px;
    }
 
`;




