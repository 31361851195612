import React from 'react'
import Navbar from './Navbar'
import { FAQ_data } from "./assets";
import styled from 'styled-components';
import FAQItems from './FAQItems';

function FAQ({ disconnect, connectWallet, active, account, library }) {
    return (
        <>
            <Navbar connectWallet={connectWallet} disconnect={disconnect} account={account} active={account !== undefined} />

            <FAQContainer>
                <h1>FAQs.</h1>
                <p>Check out some of the most commonly asked questions from members</p>

                {FAQ_data.map((item) => (
                    <FAQItems
                        id={item.id}
                        question={item.question}
                        answer={item.answer}
                    />
                ))}
            </FAQContainer>


        </>

    )
}

export default FAQ

const FAQContainer = styled.div`
    color:white;
    display:flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;
    padding-top:20px;
    /* width:80%; */
    *{
        color:white;
    }
    >p{
        margin-bottom: 30px;
    }
`;