import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { contractAddress, abi, step1, step2, step3, gif125x125, gif120x600, gif468x60 } from "./assets";
import Navbar from './Navbar';
import { ethers } from 'ethers';
import { width } from '@mui/system';
import { right } from '@popperjs/core';

function Share({ disconnect, connectWallet, active, account, library }) {
  const baseURL = "https://thebam.io/"

  const [registered, setIsRegistered] = useState(false);
  const [clicked, setClicked] = useState(false);

  const copyLink = () => {
    navigator.clipboard.writeText(baseURL + account.toString());
    setClicked(true)
  }

  const CheckRegistered = async () => {
    
    const contractRead = new ethers.Contract(contractAddress, abi, library);
    const isRegistered = await contractRead.checkUserRegistry(account.toString());
    console.log(isRegistered)


    const owner = await contractRead.owner();

    if (account.toLowerCase() === owner.toLowerCase()) {
      setIsRegistered(true);
    }
    else {
      setIsRegistered(isRegistered);
    }
  }

  const [shareLink, setShareLink] = useState(baseURL)
  useEffect(() => {
    async function Call() {
      await CheckRegistered();
    }
    Call();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [library]);



  return (
    <>
      <script type="text/javascript" src="//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-62cd2d35c0656898"></script>
      <Navbar connectWallet={connectWallet} disconnect={disconnect} account={account} active={account !== undefined} />

      <ShareContainer>
        <h1>SHARE.</h1>
        <Details>
          <DetailsLeft>
            <h2 >Invite Now!</h2>
            <p>
              Share the benefits of DeFi with other likeminded people. By building a team, you can accelerate the growth of your crypto even faster.
            </p>
            {active ?
              registered ? <>
                <Box>
                <input value={baseURL + account.toString()} style={{ width: '100%', marginRight: '10%' }}></input>
                  <Button onClick={copyLink} variant="contained" >
                    {!clicked ? "Copy" : "Copied!"}
                  </Button>
                </Box>
                <ShareBox>
                  <div className="addthis_inline_share_toolbox" data-url={shareLink} data-title="B.A.M"></div>
                </ShareBox>
              </>
                :
                <Box className='red'>
                  <h1>! Please stake to access your link</h1>
                </Box>
              : (
                <Box>
                  
                  <Button onClick={connectWallet} variant="contained" >
                    Connect Wallet
                  </Button>
                </Box>
              )}

            <p>
              Please note, sharing your link is not necessary to generate a passive income.
            </p>

            <div class="addthis_inline_share_toolbox" data-url="www.google.com" data-title="The Title">

            </div>
          </DetailsLeft>
          <DetailsRight>
            <h2>
              What you can earn
            </h2>

            <p>As your team and network expands, so do your earnings. Generate instant BNB commissions and residual bonuses as others join you.</p>


            <table>
              <tbody>
                <TableRow>
                  <td>Level 1</td>
                  <td className="yellow">10%</td>
                </TableRow>
                <TableRow>
                  <td>Level 2</td>
                  <td className="yellow">5%</td>
                </TableRow>
                <TableRow>
                  <td>Level 3</td>
                  <td className="yellow">3%</td>
                </TableRow>
                <TableRow>
                  <td>Level 4</td>
                  <td className="yellow">2%</td>
                </TableRow>
                <TableRow>
                  <td>Level 5</td>
                  <td className="yellow">1%</td>
                </TableRow>
              </tbody>
            </table>
          </DetailsRight>
        </Details>
        <Steps>
          <h1>How it Works</h1>
          <StepList>
            <Step>
              <img src={step1} alt="step1" />
              <Info>
                <h3>
                  STEP 1 - DEPOSIT</h3>
                <p>Deposit in BNB using a wallet that supports BSC chain. Metamask is a popular wallet that we recommend using. MIN deposit: 0.0125  BNB</p>
              </Info>
            </Step>
            <Step>
              <img src={step2} alt="step2" />
              <Info>
                <h3>
                  STEP 2 - SHARE
                </h3>
                <p>Share your BAM link with others & build your team. Tips: Use social media like: Facebook, YouTube, Twitter & Instagram.</p>
              </Info>
            </Step>
            <Step>
              <img src={step3} alt="step3" />
              <Info>
                <h3>
                  STEP 3 - EARN</h3>
                <p>Earn instant BNB commissions when you invite others to join & deposit. As your team grows, so will your earnings!</p>
              </Info>
            </Step>
          </StepList>
        </Steps>

        <BannerContainer>
          <h1>Marketing</h1>

          <Banners>
            <a href={gif125x125} download="125x125">
              <Button variant="contained">125x125</Button>
            </a>
            <a href={gif468x60} download="468x60" >
              <Button variant="contained">468x60</Button>
            </a>
            <a href={gif120x600} download="120x600" >
              <Button variant="contained">120x600</Button>
            </a>
          </Banners>

        </BannerContainer>

      </ShareContainer>
    </>
  )
}

export default Share

const ShareContainer = styled.div`
  color:white;
  display:flex;
  flex-direction: column;
  align-items:center;
  justify-content: center;
  /* border:1px solid white; */
  padding-top:20px;

  >h1{
    font-size:48px;
    font-weight: 700;
    font-family: 'Kanit', sans-serif;
    margin: 20px;
    margin-bottom: 40px;
  }

`;

const Details = styled.div`
  display:flex;
  justify-content: center;
  width:90%;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-bottom: 20px;
`;
const DetailsLeft = styled.div`
  max-width:43%;
  border-right: 3px solid white;
  padding:0 20px;
  display:flex;
  flex-direction: column;
  justify-content:flex-start;
  align-items:left;
  >h2{
    font-size: 33px;
    font-weight:300;
    margin-bottom:5px;
  }
  >p{
    color:gray;
    font-size: 20px;
  }

  >.red{
    background-color: red;

    >h1{
      font-weight: 400;
    }
  }

  @media  (max-width:1024px){
    margin-top: 20px;
    margin-bottom: 20px;
    align-items: center;
    max-width: 100%;
    border:none;

    >p{
      text-align:center;
    }
  }



`;
const DetailsRight = styled.div`
  max-width:43%;
  padding:0 20px;
  display:flex;

  flex-direction: column;
  justify-content:flex-start;
  align-items:left;
  >h2{
    font-size: 33px;
    font-weight:300;
    margin-bottom:5px;

  }
  >p{
    color:gray;
    font-size: 20px;
  }


  >table{
  width:30%;
  min-width:160px;
  margin-top: 20px;
  margin-bottom: 20px;
  border-spacing: 10px;
  }

  @media  (max-width:1024px){
    margin-top: 20px;
    margin-bottom: 20px;
    align-items: center;
    max-width: 100%;
    border:none;

    >p{
      text-align:center;
    }
  }

`;

const Box = styled.div`
  width:90%;
  background-color:#39b54a;
  padding:15px 20px;
  align-self: center;
  display:flex;
  align-items:center;
  justify-content:center;
  align-items: center;

  margin:15px auto;

  >Button{
    background-color:black;
    text-transform: none;
    padding-left:30px;
    padding-right:30px;
    font-weight:700;
    font-size: 16px;
    :hover{
      background-color: gray;
    }
  }
`;

const TableRow = styled.tr`
  >td{
    font-size:18px;
    font-weight: 700;
    text-align: center;
  }

  .yellow{
    color:black;
    background-color: #ffd005;;
  }
`;

const Steps = styled.div`
  display:flex;
  flex-direction: column;
  width:80%;
  margin-top:80px;
  margin-bottom: 80px;
  >h1{
    font-weight: 300;
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;

const StepList = styled.div`
  display:flex;
  justify-content:space-between;
  flex-wrap: wrap;
  align-items:center;

  @media (max-width:1300px) {
      justify-content: center;
      margin:10px;
  }
`;

const Step = styled.div`
  background-color: white;
  color: black;
  width:30%;
  min-width:280px;
  max-width: 300px;
  min-height:160px;
  
  padding:15px;
  margin:10px;
  display:flex;
  flex-wrap: wrap;
  
justify-content:center;
  /* @media (max-width:1280px) {
      width:100%;
      align-items: center;
      margin:10px;
  } */
  >img{
    object-fit: contain;
    width:100px;
    margin-right: 15px;
    /* flex:.4; */
  }
`;

const Info = styled.div`
  flex:1;
/* padding:25px; */
>p{
  font-size: 14px;
  color:gray;
}
`;

const BannerContainer = styled.div`
  display:flex;
  flex-direction: column;
  width:80%;
  margin-top:80px;
  margin-bottom: 80px;
  >h1{
    font-weight: 300;
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;

const Banners = styled.div`
  display:flex;
  justify-content:space-between;
  width:60%;
  flex-wrap: wrap;

  margin-top:20px;
  margin-bottom: 20px;

  @media  (max-width:1024px) {
    width:100%;
  }

  >a{
    text-decoration: none;;
  }
  >a>Button{
    padding-left:44px;
    padding-right:44px;

    background-color: #39b54a;

    :focus{
      background-color: #058905;
    }
  }

`;

const ShareBox = styled.div`
  display:flex;
  >.fb{
    background-color:#3b5998;
  }
  >.twitter{
    background-color: #1da1f2;
  }
  >.whatsapp{
    background-color: #4cc247;
  }
`;
