import bamlogoweb from "../assets/bamlogoweb.png";
import homeicon from "../assets/homeicon.png";
import enginearrowicon from "../assets/enginearrowicon.png";
import getstartedicon from "../assets/getstartedicon.png";
import whitepapericon from "../assets/whitepapericon.png";
import bnb from "../assets/bnb.png";
import addfuel from "../assets/addfuel.png";
import backgroundImage from "../assets/background-image.jpg";
import bnbcoinicon from "../assets/bnbcoinicon.png";
import gettingStarted from "../assets/gettingstartedsteps.png"
import Whitepaper from "../assets/Whitepaper.pdf";
import gif125x125 from "../assets/125x125.gif"
import gif120x600 from "../assets/120x600.gif"
import gif468x60 from "../assets/468x60.gif"
import cogwheel from "../assets/cogwheel.png"
import tractorIcon from "../assets/tractoricon.png"
import injectionIcon from "../assets/injectionicon.png"
import defiIcon from "../assets/defiicon.png"
import FAQIcon from "../assets/FAQIcon.png"
import shareIcon from "../assets/shareIcon.png"
import contractIcon from "../assets/Contract.png"

import bnblogoWhite from "../assets/bnblogowhite.png"

import digitalWallet from "../assets/LottieAnimations/digitalWallet.json"
import savings from "../assets/LottieAnimations/savings.json"
import settings from "../assets/LottieAnimations/settings.json"
import solarEnergy from "../assets/LottieAnimations/solarEnergy.json"
import trendingUp from "../assets/LottieAnimations/trendingUp.json"
import wallet from "../assets/LottieAnimations/wallet-outline.json"
import teamMembers from "../assets/LottieAnimations/teamMembers.json"
import withdrawhistoryIcon from "../assets/withdrawhistoryicon.png"

import step1 from "../assets/step1.png"
import step2 from "../assets/step2.png"
import step3 from "../assets/step3.png"

import secure from "../assets/secure.png"

// import homeVideo from "../assets/homeVideo.mp4"

import passiveEarningIcon from "../assets/passiveearnings.png"



import teamearnings from "../assets/teamearnings.png"
import loading from "../assets/loading.svg"

const contractAddress = "0x438832d5cf6719ab8498e8e634e3aea4431f080b"

const abi = [
	{
		"inputs": [],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "Paused",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "Resumed",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "address",
				"name": "user",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "Staked",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "address",
				"name": "user",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "address",
				"name": "referee",
				"type": "address"
			}
		],
		"name": "UserRegistered",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "address",
				"name": "user",
				"type": "address"
			}
		],
		"name": "UserReinstated",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "address",
				"name": "user",
				"type": "address"
			}
		],
		"name": "UserSuspended",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "address",
				"name": "user",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amountRequested",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amountRecieved",
				"type": "uint256"
			}
		],
		"name": "Withdrawal",
		"type": "event"
	},
	{
		"stateMutability": "nonpayable",
		"type": "fallback"
	},
	{
		"inputs": [],
		"name": "AddLiquidity",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "AdminEarnings",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "teamSize",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "downlineBalance",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "totalEarnings",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "currentBalance",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "lastUpdated",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "teamEarnings",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "depositFees",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "withdrawFees",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "referralFees",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "residualCommissions",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "performanceFeeCommissions",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "residualCommissionsPerDay",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "perFormanceFeeCommissionPerDay",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_amount",
				"type": "uint256"
			}
		],
		"name": "AntiWhaleTax",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_rate_A",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_rate_B",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_rate_C",
				"type": "uint256"
			}
		],
		"name": "ChangeEarningsRate",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_maxStake",
				"type": "uint256"
			}
		],
		"name": "ChangeMaxStake",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_minStake",
				"type": "uint256"
			}
		],
		"name": "ChangeMinStake",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_amount",
				"type": "uint256"
			}
		],
		"name": "WithdrawAdminEarnings",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "acceptOwnerShip",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_fee",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_adminShare",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_liquidity",
				"type": "uint256"
			}
		],
		"name": "changeDepositFee",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_fee",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_adminShare",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_liquidity",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_upline",
				"type": "uint256"
			}
		],
		"name": "changePerformanceFee",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_fee",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_adminShare",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_liquidity",
				"type": "uint256"
			}
		],
		"name": "changeReferralCommissionFee",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_fee",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_adminShare",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_liquidity",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_directSponsor",
				"type": "uint256"
			}
		],
		"name": "changeWithdrawalFee",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_user",
				"type": "address"
			}
		],
		"name": "checkUserRegistry",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "dailyEarnings_A",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "dailyEarnings_B",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "dailyEarnings_C",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "depositFee",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "fee",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "adminShare",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "liquidity",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "directSponsor",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "upline",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_amount",
				"type": "uint256"
			}
		],
		"name": "getEarningsRate",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "isActive",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_amount",
				"type": "uint256"
			}
		],
		"name": "maxEligibleLevelForReferralCommission",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "pure",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_user",
				"type": "address"
			}
		],
		"name": "maxReturn",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "maxStakedBalance",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "maximumReturnPercentage",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "maximumStakeValue",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "minimumStakeValue",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "minimumWithdrawalAmount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "nextOwner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "pauseContract",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "pauseTime",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "percentageDivider",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "performanceFee",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "fee",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "adminShare",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "liquidity",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "directSponsor",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "upline",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "referralCommissionFee",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "fee",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "adminShare",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "liquidity",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "directSponsor",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "upline",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "referralLevelCommission",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_user",
				"type": "address"
			}
		],
		"name": "reinstateUser",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "resumeContract",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "resumeTime",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_user",
				"type": "address"
			}
		],
		"name": "seeTeamSize",
		"outputs": [
			{
				"internalType": "uint256[5]",
				"name": "",
				"type": "uint256[5]"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "referree",
				"type": "address"
			}
		],
		"name": "stakeBNB",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "startTime",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_user",
				"type": "address"
			}
		],
		"name": "suspendUser",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalBNBStaked",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalBNBWithdrawan",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalBNBWithdrawanAfterDeduction",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalFeePaid",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "viewAdminEarnings",
		"outputs": [
			{
				"components": [
					{
						"internalType": "uint256",
						"name": "teamSize",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "downlineBalance",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "totalEarnings",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "currentBalance",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "lastUpdated",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "teamEarnings",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "depositFees",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "withdrawFees",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "referralFees",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "residualCommissions",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "performanceFeeCommissions",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "residualCommissionsPerDay",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "perFormanceFeeCommissionPerDay",
						"type": "uint256"
					}
				],
				"internalType": "struct BAM.adminEarnings",
				"name": "",
				"type": "tuple"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_user",
				"type": "address"
			}
		],
		"name": "viewUserBalance",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_user",
				"type": "address"
			}
		],
		"name": "viewUserDetails",
		"outputs": [
			{
				"components": [
					{
						"internalType": "bool",
						"name": "isRegistered",
						"type": "bool"
					},
					{
						"internalType": "bool",
						"name": "isSuspended",
						"type": "bool"
					},
					{
						"internalType": "uint256",
						"name": "balance",
						"type": "uint256"
					},
					{
						"internalType": "uint256[]",
						"name": "withdrawalrequestedAmount",
						"type": "uint256[]"
					},
					{
						"internalType": "uint256[]",
						"name": "withdrawalAmountRecieved",
						"type": "uint256[]"
					},
					{
						"internalType": "uint256[]",
						"name": "withdrawalTime",
						"type": "uint256[]"
					},
					{
						"components": [
							{
								"internalType": "bool",
								"name": "isActive",
								"type": "bool"
							},
							{
								"internalType": "bool",
								"name": "maxed",
								"type": "bool"
							},
							{
								"internalType": "uint256",
								"name": "stakedAmount",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "activeStake",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "dailyEarningsBeforeFee",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "dailyEarningsAfterFee",
								"type": "uint256"
							},
							{
								"internalType": "uint256[5]",
								"name": "referralEarning",
								"type": "uint256[5]"
							},
							{
								"internalType": "uint256[5]",
								"name": "missedReferralEarnings",
								"type": "uint256[5]"
							},
							{
								"internalType": "uint256[]",
								"name": "stakeAmountHistory",
								"type": "uint256[]"
							},
							{
								"internalType": "uint256[]",
								"name": "stakeTimeHistory",
								"type": "uint256[]"
							},
							{
								"internalType": "uint256",
								"name": "residualCommissionPerDay",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "totalResidualCommission",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "totalReferralCommission",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "totalWithdrawalCommission",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "totalEarnings",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "lastUpdated",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "creationTime",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "dailyEarningRate",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "maxReferralLevel",
								"type": "uint256"
							}
						],
						"internalType": "struct BAM.Stake",
						"name": "activeStake",
						"type": "tuple"
					},
					{
						"internalType": "uint256",
						"name": "cycles",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "previousEarnings",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "teamEarnings",
						"type": "uint256"
					},
					{
						"internalType": "address",
						"name": "referredBy",
						"type": "address"
					},
					{
						"internalType": "uint256[5]",
						"name": "downlineLevelStakedAmount",
						"type": "uint256[5]"
					},
					{
						"internalType": "uint256[5]",
						"name": "downlineLevelActiveStakes",
						"type": "uint256[5]"
					},
					{
						"internalType": "uint256[5]",
						"name": "teamsize",
						"type": "uint256[5]"
					}
				],
				"internalType": "struct BAM.User",
				"name": "",
				"type": "tuple"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_user",
				"type": "address"
			}
		],
		"name": "viewUserStake",
		"outputs": [
			{
				"components": [
					{
						"internalType": "bool",
						"name": "isActive",
						"type": "bool"
					},
					{
						"internalType": "bool",
						"name": "maxed",
						"type": "bool"
					},
					{
						"internalType": "uint256",
						"name": "stakedAmount",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "activeStake",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "dailyEarningsBeforeFee",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "dailyEarningsAfterFee",
						"type": "uint256"
					},
					{
						"internalType": "uint256[5]",
						"name": "referralEarning",
						"type": "uint256[5]"
					},
					{
						"internalType": "uint256[5]",
						"name": "missedReferralEarnings",
						"type": "uint256[5]"
					},
					{
						"internalType": "uint256[]",
						"name": "stakeAmountHistory",
						"type": "uint256[]"
					},
					{
						"internalType": "uint256[]",
						"name": "stakeTimeHistory",
						"type": "uint256[]"
					},
					{
						"internalType": "uint256",
						"name": "residualCommissionPerDay",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "totalResidualCommission",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "totalReferralCommission",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "totalWithdrawalCommission",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "totalEarnings",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "lastUpdated",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "creationTime",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "dailyEarningRate",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "maxReferralLevel",
						"type": "uint256"
					}
				],
				"internalType": "struct BAM.Stake",
				"name": "",
				"type": "tuple"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_amount",
				"type": "uint256"
			}
		],
		"name": "withdrawEarnings",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "withdrawalFee",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "fee",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "adminShare",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "liquidity",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "directSponsor",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "upline",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"stateMutability": "payable",
		"type": "receive"
	}
]

const FAQ_data = [
	{
	  id: 1,
	  question: "What Is B.A.M?",
	  answer: "B.A.M is an acceleration model for BNB asset accumulation. It’s an autonomous system built on the BSC blockchain layer. B.A.M offers members an opportunity in DeFi to provide liquidity and generate passive rewards. Our decentralized pool provides a frictionless environment for members to stake & earn perpetually in realtime."
	},
	{
	  id: 2,
	  question: "Who is behind B.A.M?",
	  answer: "B.A.M is founded by a small & focused circle of crypto enthusiasts and experienced fintech leaders. Our core objective is to bring you the benefits of a new financial ecosystem. Abandon the pressures of speculation in the markets and rely on a go-to accumulation & growth mechanism, for steady and reliable crypto gains. Bypass the poorly constructed and managed projects that grip the crypto landscape."
	},
	{
	  id: 3,
	  question: "What is the minimum deposit amount?",
	  answer: "Our minimum deposit amount is 0.0125. After fees have been deducted, we allocate an active stake to your account. You can add any additional funds to your active stake at any time."
	},
	{
	  id: 4,
	  question: "Is there a limit to how much I can stake?",
	  answer: "There are no limits to how many times you can deposit & stake in your account. The maximum active stake you can have running in your account is 500 BNB. Once your stake ends, you can re-deposit & stake again."
	},
	{
	  id: 5,
	  question: "When will my stake end?",
	  answer: "Your stake will remain active until you reach 2.5x leverage against your allocated stake amount. By example, if you have a stake of 10 BNB, you can expect to receive 25 BNB in rewards (before fees)."
	},
	{
	  id: 6,
	  question: "Can I compound my rewards?",
	  answer: "You can deposit additional BNB & stake into the system. This will be added & combined to your existing, active stake amount that you will earn cumulative rewards from."
	},
	{
	  id: 7,
	  question: "Are there any limits with withdrawals?",
	  answer: "You can withdraw whenever you want, providing you have a minimum balance of 0.01 BNB in your wallet."
	},
	{
	  id: 8,
	  question: "How are my daily rewards generated?",
	  answer: "As a member of B.A.M, your registration grants you access to become a liquidity provider. When you deposit & stake, you’re allocating assets to our pool. You’ll also earn rewards as others deposit & withdraw BNB assets you’ve pooled for. The rewards are never guaranteed and are solely based on the available liquidity of a digital asset; BNB. A proportion of the assets are split into other income-generating Dapps and protocols. This reduces risk, increases asset spread & creates new opportunities to generate surplus liquidity back into the B.A.M. ecosystem. This will result in overall increased liquidity which will impact asset stability, reducing risk further."
	},
	{
	  id: 9,
	  question: "How much can I earn each day?",
	  answer: "The rewards generated will fluctuate and will depend on your level of activity and your active stake amount in the contract. Please see the whitepaper for further details."
	},
	{
	  id: 10,
	  question: "Do you charge any fees?",
	  answer: "Fees are necessary to provide a long-term and stable ecosystem for members. We earn a small 2.5% in most scenarios and other fees are injected back into the system for added liquidity."
	},
	{
	  id: 11,
	  question: "I’ve built a team but my stake has now ended. Will I still earn?",
	  answer: "In order to generate passive rewards and commissions from your team, you must have an active stake running in your account. Should your stake end, any commissions due will be “missed” temporarily until you stake again."
	},
	{
	  id: 12,
	  question: "Someone staked in my team but I missed commissions. Why?",
	  answer: "We specify criteria that you must have a minimum active stake to earn from specific levels in your team. Please see the whitepaper for further details."
	},
	{
	  id: 13,
	  question: "Is there any risk to my capital in this contract?",
	  answer: "The cryptocurrencies markets carry significant risk. Members should be aware there is always a risk of potential loss to your capital. You should only participate if you are willing to lose some or all of your capital."
	},
	{
	  id: 14,
	  question: "Has the contract been audited?",
	  answer: "The contract has received multi-audit stamps to long-term sustainability and accuracy of data logic. The contract is immutable, so the foundation cannot be altered in any way, shape, or form."
	},
	{
	  id: 15,
	  question: "Can the contract be changed?",
	  answer: "We have safety measures in place that ensure healthy and optimal liquidity for members. This will not affect the daily performance of rewards generated."
	},
	{
	  id: 16,
	  question: "Can I withdraw my principal amount?",
	  answer: "Your stake amount will remain locked to ensure a stable & healthy environment for all members. Rewards generated will include your principal amount + profits, available to you in real-time for withdrawal."
	},
	{
	  id: 17,
	  question: "Do I have to share with others?",
	  answer: "You do not have to share the opportunity with others in order to generate passive rewards. However, in doing so, you can accelerate your earnings."
	},
	{
	  id: 18,
	  question: "How long will this run for and is it guaranteed?",
	  answer: "We inevitably cannot control the timescales or indeed, the outcome when staking cryptocurrency with our contract. B.A.M is a community-based project and as such, it can be impacted by wild fluctuations in the markets and the price of cryptocurrency assets themselves. 3rd party dependency of protocols linked to B.A.M can also be negatively impacted and affected. Cryptocurrencies by their very nature carry a high level of risk, and may not be suitable for all investors. Before deciding to stake cryptocurrency, you should carefully consider your investment objectives, level of experience, and risk appetite. The possibility exists that you could sustain a loss of some, or all of your initial investment and therefore, you should not invest money that you cannot afford to lose. You should be aware of all the risks associated with cryptocurrency staking, and seek advice from an independent financial advisor. Any opinions, news, research, analyses, prices, or other information contained on this website is provided as general market commentary, and does not constitute investment advice. Under no circumstances do we guarantee a return on your investment."
	}
  ];
  
export { loading, secure, FAQ_data, contractIcon, passiveEarningIcon, withdrawhistoryIcon, teamMembers, bamlogoweb, homeicon, bnblogoWhite, enginearrowicon, getstartedicon, whitepapericon, backgroundImage, abi, contractAddress, addfuel, bnbcoinicon, gettingStarted, Whitepaper, step1, step2, step3, gif125x125, gif120x600, gif468x60, teamearnings, cogwheel, digitalWallet, savings, settings, solarEnergy, trendingUp, wallet, tractorIcon, injectionIcon, defiIcon, FAQIcon, shareIcon };
