import React from 'react'
import styled from 'styled-components'

import { gettingStarted } from './assets';
import Navbar from './Navbar';


function GetStarted({ disconnect, connectWallet, active, account }) {
  return (<>
    <Navbar connectWallet={connectWallet} disconnect={disconnect} account={account} active={account !== undefined} />

    <MainContainer>
      <HeroText>
        <h1>GETTING<br />STARTED.</h1>
        <p>Follow the 6 simple steps below.<br />
          Once complete, you’ll be set up & running with BAM.</p>
      </HeroText>
      <img src={gettingStarted} alt="Getting started" />
    </MainContainer>
  </>

  )
}

export default GetStarted

const MainContainer = styled.div`
  display:flex;
  flex-direction: column;
  justify-content:center;
  align-items:center;
  >img{
    object-fit:contain;
    width:78vw;
  }

`;

const HeroText = styled.div`
  color:white;
  text-align:center;
  margin-top:3rem;
  margin-bottom:5.5rem;
  >h1{
    font-size: 50px;
    word-wrap: break-word;
    line-height:1.2;
    overflow:hidden;
  }
  >p{
    margin-top: 10px;
    margin-bottom: 10px;
  }
  `;
