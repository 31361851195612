import React from 'react'
import styled from 'styled-components'

function Meter({ progress, earnings }) {
    return (
        <>
            <MeterOuter>
                <MeterBar style={{ background: `conic-gradient(#90ee90 ${progress}%,transparent 0` }} />

                <MeterInner>
                    <span class="number" style={{ fontSize: "70px" }}>{parseFloat(earnings).toFixed(4)}</span>
                    <p>BNB</p>
                </MeterInner>
            </MeterOuter>
        </>
    )
}

export default Meter

const MeterOuter = styled.div`
    width: 250px;
    height: 250px;
    border-radius: 50%;
    border: 2px solid white;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;

    margin-left: auto;
    margin-right: auto;
    position:relative;
    /* padding:20px; */
    aspect-ratio:1;
    overflow:hidden;
`

const MeterInner = styled.div`
    border: 2px solid ;
    border-radius: 100%;
    height: 180px;
    width: 180px;
    display:flex;
    flex-direction: column;
    justify-content:center;
    align-items:center;
    vertical-align: middle;
    text-align: center;
    color:white;
    >p{
        font-size:1.5rem;
    }
    >.number{
        font-size:3rem !important;
    }
`;

const MeterBar = styled.div`
    position:absolute;
    border-radius: 100%;
    height: 220px;
    width: 220px;

    /* background-image:  conic-gradient(#90ee90 1%,transparent 0); */
    transform: rotate(229deg);
    mask: radial-gradient(farthest-side,#0000 calc(90%),#000 calc(90%));
`;