import React from 'react'
import styled from 'styled-components';
import { Whitepaper } from './assets';

import TelegramIcon from '@mui/icons-material/Telegram';
import { secure, bamlogoweb, homeicon, enginearrowicon, getstartedicon, whitepapericon, backgroundImage, FAQIcon, shareIcon, contractIcon } from './assets';

import { NavLink, useParams } from 'react-router-dom';

function Footer() {

    const { referee } = useParams();

    return (
        <>
            <FooterContainer>
                <FooterHead>

                    <FooterHeadItem>
                        <h2>GET IN TOUCH</h2>
                       <div><TelegramIcon sx={{
                            color: "#0088cc"
                        }} />
                             <StyledLink href="https://t.me/bamdefi" className="tgLink"><h4>Join the Telegram Group</h4></StyledLink>
                             </div>

                    </FooterHeadItem>
                    <FooterHeadItem>
                        <h2>LINKS</h2>
                        <NavLink to={referee === undefined ? "/" : `/${referee}`}>
                            <FooterLinks>
                                <img src={homeicon} alt="homeicon" />
                                <h3>Home</h3>
                            </FooterLinks>
                        </NavLink>

                        <NavLink to={referee === undefined ? "/GrowthEngine/" : `/GrowthEngine/${referee}`}>
                            <FooterLinks>
                                <img src={enginearrowicon} alt="enginearrowicon" />
                                <h3>Growth Engine</h3>
                            </FooterLinks>
                        </NavLink>

                        <a className='whitepaper' rel="noreferrer" href={Whitepaper} target="_blank" >
                            <FooterLinks>
                                <img src={whitepapericon} alt="whitepapericon" />
                                <h3>Whitepaper</h3>
                            </FooterLinks>
                        </a>

                        <NavLink to={referee === undefined ? "/GetStarted/" : `/GetStarted/${referee}`}>
                            <FooterLinks>
                                <img src={getstartedicon} alt="getstartedicon" />
                                <h3>Getting started</h3>
                            </FooterLinks>
                        </NavLink>

                        <NavLink to={referee === undefined ? "/share" : `/share/${referee}`}>
                            <FooterLinks>
                                <img src={shareIcon} alt="share icon" />
                                <h3>Share</h3>
                            </FooterLinks>
                        </NavLink>

                        <NavLink to={referee === undefined ? "/faq" : `/faq/${referee}`}>
                            <FooterLinks>
                                <img src={FAQIcon} alt="faq icon" />
                                <h3>FAQ</h3>
                            </FooterLinks>
                        </NavLink>
                        
                        <NavLink to={"https://bscscan.com/address/0x438832d5cf6719ab8498e8e634e3aea4431f080b"} target="_blank">
                            <FooterLinks>
                                <img src={contractIcon} alt="contract icon" />
                                <h3>Contract</h3>
                            </FooterLinks>
                        </NavLink>


                    </FooterHeadItem>
                    <FooterHeadItem>
                        <img src={bamlogoweb} alt="bam logo" />
                        <h3>Be <i>your</i> own bank.</h3>
                        <img className="secure" src={secure} alt="secure" />
                    </FooterHeadItem>

                </FooterHead>
                <FooterBottom>
                    <h5>© 2023 B.A.M All Rights Reserved</h5>
                    <p>
                        Investing in cryptocurrencies carries a high level of risk, and may not be suitable for all investors. Before deciding to invest in a Decentralized Finance business model (DeFi) you should carefully consider your investment objectives, level of experience, and risk appetite. The possibility exists that you could sustain a loss of some, or all of your initial investment and therefore you should not invest money that you cannot afford to lose. You should be aware of all the risks associated with cryptocurrencies, and seek advice from an independent financial advisor. Investing in a DeFi protocol will not guarantee a return on your investment. In fact, due to the volatile nature of cryptocurrencies, a return may not be possible if market factors beyond your control, affect the growth, liquidity & performance of our smartcontract.
                    </p>
                </FooterBottom>
            </FooterContainer>

        </>
    )
}

export default Footer


const StyledLink = styled.a`
  color: white;
  text-decoration: none;
`;

const FooterContainer = styled.div`
    padding: 20px var(--padding_right);
    position: relative;

    background-image: url(${backgroundImage});
    background-size: contain;
    background-position: right;
    background-repeat: no-repeat;
    margin-top:2rem;
    color:#cfcbcb;

    @media(max-width: 768px){
        padding: 0px;
        margin: 25px 20px;
    }

`;

const FooterHead = styled.div`
    display:flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin:2rem auto;
`;

const FooterHeadItem = styled.div`
    >a{
        text-decoration: none;
        color: white;
    }

    width: 25%;
    @media(max-width: 768px){
       width: 100%;

       &:nth-child(2){
        margin-top: 20px;
        margin-bottom: 20px;
       }
    }
    >h2{
        font-weight: 600;
        font-size: 20px;
    }
    >h3{
        font-size: 18px;
        font-weight: 300;
    }
    >div{
        display:flex;
        align-items: center;
        >h4{
            margin-left: 5px;
            font-size: 18px;
            font-weight: 400;
        }
    }

    >img{
        object-fit: contain;
        width:35%;
        margin-top: 5px;
    }
    >.secure{
        width:50%;
    }
`;

const FooterLinks = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin:10px auto;
    >img{
        object-fit: contain;
        width:28px;
        margin-right: 10px;
    }

    >h3{
        font-weight: 400;
    }
`;

const FooterBottom = styled.div`

    >h5{
        font-weight: 400;
        margin:20px auto;
    }

    >p{
        font-weight: 400;
        font-size: small;
    }
`;

